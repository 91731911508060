import { NgModule } from '@angular/core';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';
import { QuizPlayerQuestionComponent } from 'modules/quiz/player/quiz-player-question.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QuizPlayerComponent } from 'modules/quiz/player/quiz-player.component';
import { QuizResultService } from 'modules/quiz/services/quiz-result.service';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { QuizPlayerService } from 'modules/quiz/services/quiz-player.service';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';


@NgModule({
  imports: [
    LmsComponentsModule,
    CommonModule,
    FormsModule,
    NgbProgressbar
  ],
  declarations: [
    QuizPlayerQuestionComponent,
    QuizPlayerComponent
  ],
  providers: [
    QuizService,
    QuizQuestionService,
    QuizResultService,
    QuizPlayerService,
    { provide: QuizTemporaryFilesService },
    {
      provide: 'quizTimeTrackingSettings', useValue: {
        interval: 10e3,
      }
    },
    QuizOptionsService
  ],
  exports: [
    QuizPlayerQuestionComponent,
    QuizPlayerComponent
  ]
})
export class QuizModule {}
