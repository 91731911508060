import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


enum UserEmailOptInEndpoints {
  POST = '/a/user/opt_in/'
}


@Injectable()
export class UserEmailOptInService {
  constructor(private http: HttpClient) { }

  resendAction(throwHttpErrors?: boolean) {
    return this.doAction('resend', throwHttpErrors);
  }

  private doAction(action: string, throwHttpErrors = false) {
    // it returns boolean value
    return this.http.post(
      UserEmailOptInEndpoints.POST, { action },
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }
}
