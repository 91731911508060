<div *ngIf="filter.visible">
  <div *ngIf="!fullScreen">
    <div ngbAccordion *ngIf="!fullScreen && filter.appearance !== 'button'">
      <div ngbAccordionItem [collapsed]="!isOpen">
        <div ngbAccordionHeader>
          <button ngbAccordionButton>
            <span class="pull-right fa fa-fw fa-caret-down"></span>
            <span>{{filter.label}}</span>
            <span class="badge badge-default display-none search-count-badge"
                  *ngIf="filter.selectedItems?.length">{{filter.selectedItems.length}}</span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <ul class="content-list">
                <li class="content-list-item" *ngIf="filter.selectedItems?.length">
                  <div class="flex">
                    <div class="flex-row">
                      <div class="flex-col flex-col-grow">
                        <a href="#" title="" (click)="openFilter()">{{filter.selectedItems.length}} filters selected</a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="content-list-item">
                  <div class="flex">
                    <div class="flex-row">
                      <div class="flex-col flex-col-grow">
                        <button type="button" class="btn btn-success btn-block"
                                *ngIf="filter.selectedItems && filter.selectedItems.length <= 0"
                                (click)="openFilter()">Select Filters</button>
                        <button type="button" class="btn btn-danger btn-block"
                                *ngIf="filter.selectedItems && filter.selectedItems.length > 0"
                                (click)="clearSelection()">Remove Filters</button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-default btn-block" *ngIf="filter.appearance === 'button'"
            (click)="openFilter()">{{filter.label}}</button>

  </div>
  <div *ngIf="fullScreen">
    <div [ngSwitch]="filter.popupOptions.component">
      <course-attribute-filter *ngSwitchCase="'courseAttributeFilter'"
                               [filterService]="filterService"></course-attribute-filter>
      <competency-filter *ngSwitchCase="'competencyFilter'" [filterService]="filterService"></competency-filter>
      <session-label-filter *ngSwitchCase="'sessionLabelFilter'" [filterService]="filterService"></session-label-filter>
      <group-tags-search-filter *ngSwitchCase="'groupTagsSearchFilter'"
                                [filterService]="filterService"></group-tags-search-filter>
    </div>
  </div>
</div>
