<div ngModelGroup name="subjectAreasForm" #subjectAreasForm="ngModelGroup">
  <div class="form-group no-margin-bottom"
       [ngClass]="{'row':consistentView, 'has-error': subjectAreasForm.invalid && (formOptions.submitted || subjectAreasForm.dirty)}">
    <label *ngIf="showTitle"
           [ngClass]="{'col-sm-3 text-left': !consistentView, 'col-sm-12 text-left': consistentView}">Subject
      area(s)</label>

    <div [ngClass]="{'col-sm-9 standard-margin-bottom': !consistentView, 'col-sm-12': consistentView}">
      <div class="panel panel-default scrollable-after-some no-margin-bottom" [ngClass]="{'required': !areasOptional}">
        <div class="panel-body">
          <ul class="list-unstyled" *ngIf="subjectAreas.length === 1">
            <li class="checkbox" *ngFor="let topic of subjectAreas[0].topics"><label>
              <input [required]="!areasOptional && !someSelected()"
                     [ngModel]="topic.selected"
                     [name]="'topic-'+topic.id"
                     [disabled]="maxCount && maxCount <= selectedSubjectAreas.length && !topic.selected"
                     (ngModelChange)="selectTopic(topic, $event)"
                     type="checkbox">{{topic.name}}</label>
            </li>
          </ul>

          <ul class="list-unstyled" *ngIf="subjectAreas.length > 1">
            <li class="checkbox" *ngFor="let subjectArea of subjectAreas">
              <div class="fa"
                   [ngClass]="{'fa-caret-right': !subjectArea.expanded, 'fa-caret-down': subjectArea.expanded}"
                   style="margin-left: -3px;width: 12px;height: 12px;text-align: center;"
                   *ngIf="subjectArea.topics.length" (click)="expandSubjectArea(subjectArea)"></div>

              <label>
                <input [disabled]="isSubjectAreaDisabled(subjectArea)" [(ngModel)]="subjectArea.selected"
                       [name]="'subjectArea-'+subjectArea.id"
                        (ngModelChange)="selectArea(subjectArea)"
                       type="checkbox">{{subjectArea.name}}
              </label>

              <ul  *ngIf="subjectArea.expanded">
                <li class="checkbox" *ngFor="let topic of subjectArea.topics"><label>
                  <input [required]="!areasOptional && !someSelected()"
                         [ngModel]="topic.selected"
                         [name]="'topic-'+topic.id"
                         (ngModelChange)="selectTopic(topic, $event)"
                         [disabled]="maxCount && maxCount <= selectedSubjectAreas.length && !topic.selected"
                         type="checkbox">{{topic.name}}</label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <p class="help-block"
         *ngIf="subjectAreasForm.invalid && (formOptions.submitted|| subjectAreasForm.dirty)">
        Subject area(s) is required
      </p>

      <div *ngIf="maxCount" class="text-muted xs-margin-bottom">Up to {{maxCount}} subject areas may be
        selected
      </div>

    </div>
  </div>

</div>
