import { Component } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';


@Component({
  selector: 'user-learning',
  templateUrl: './user-learning.component.html',
})
export class UserLearningComponent {
  userId: number;
  mode = 'transcript';

  constructor(
    private currentUser: CurrentUserService,
  ) {
    this.userId = this.currentUser.get().id;
  }
}
