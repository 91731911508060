import { NgModule } from '@angular/core';
import { AchievementDetailsComponent } from './components/achievement-details.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UserAchievementsComponent } from './components/user-achievements.component';
import { userAchievementsRoutingModule } from './user-achievements-routing.module';
import { UserAchievementsService } from './services/user-achievements.service';
import { AchievementsLeaderboardComponent } from './components/achievements-leaderboard.component';
import { UserAchievementsScalesComponent } from './components/user-achievements-scales.component';
import { UserModule } from '../user/user.module';
import { GroupAchievementsService } from 'modules/admin/group/achievements/services/group-achievements.service';
import { NavigationModule } from 'modules/navigation/navigation.module';
import { LmsCoreModule } from 'core/core.module';


@NgModule({
  declarations: [
    AchievementDetailsComponent,
    UserAchievementsComponent,
    UserAchievementsScalesComponent,
    AchievementsLeaderboardComponent,
  ],
  exports: [
    AchievementDetailsComponent,
    UserAchievementsScalesComponent,
  ],
  imports: [
    LmsComponentsModule,
    FormsModule,
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: userAchievementsRoutingModule }),
    NgbProgressbarModule,
    NgbTooltipModule,
    UserModule,
    NavigationModule,
    LmsCoreModule,
  ],
  providers: [
    UserAchievementsService,
    GroupAchievementsService,
  ]
})
export class UserAchievementsModule {}
