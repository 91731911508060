<div *ngIf="filter.visible">
  <div ngbAccordion *ngIf="!fullScreen">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{filter.label}}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div *ngFor="let item of filter.items">
              <input type="number" class="form-control" min="1" [placeholder]="item.text"
                     [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="item.value" (keydown.enter)="blur($event)"
                     (ngModelChange)="applyFilter(item.value, item.term)" />
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <ul class="content-list">
      <li class="content-list-item search-item">
        <div class="form-group number-item" *ngFor="let item of filter.items">
          <input type="number" class="form-control" min="1" [placeholder]="item.text"
                 [ngModelOptions]="{ updateOn: 'blur' }"
                 [(ngModel)]="item.value" (keydown.enter)="applyFilter(item.value, item.term)"
                 (change)="applyFilter(item.value, item.term)" />
        </div>
      </li>
    </ul>
  </div>
</div>
