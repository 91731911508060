import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiV1KeyEditConfirmationModalComponent } from './modal/api-v1-key-edit-confirmation-modal.component';
import { EMPTY, Unsubscribable, catchError, finalize, from, tap } from 'rxjs';
import { IToken } from 'modules/user/external-applications/api-v1/services/api-access.service';
import { ElmsUtils } from 'core/utils';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';


@Component({
  selector: 'api-v1-key-management',
  templateUrl: 'api-v1-key-management.component.html'
})
export class ApiV1KeyManagementComponent implements OnDestroy {
  @Input() user: any;

  modalSubscriber?: Unsubscribable;
  copyToClipBoardSubscriber?: Unsubscribable;
  password: string;
  accessToken: string;

  constructor(
    private ngbModalService: NgbModal,
    private notificationService: NotificationService,
  ) {}

  get request_url(): string {
    return `${window.location.protocol}://${window.location.host}/api/courses/`;
  }

  ngOnDestroy(): void {
    this.clearModalSubscriber();
    this.clearClipBoardSubscriber();
  }

  openModal(): void {
    const modalReference: NgbModalRef = this.ngbModalService.open(
      ApiV1KeyEditConfirmationModalComponent, { backdrop: 'static' }
    );

    (<ApiV1KeyEditConfirmationModalComponent>modalReference.componentInstance).id = this.user?.id;

    this.modalSubscriber = from(modalReference.result)
      .pipe(
        tap((response: IToken) => this.accessToken = response.token),
        catchError(() => EMPTY),
        finalize(() => this.clearModalSubscriber())
      ).subscribe();
  }

  copyAccessToken(): void {
    this.copyToClipBoardSubscriber = ElmsUtils.copyToClipboard(this.accessToken)
      .pipe(
        tap(() => this.notificationService.info('Access Key copied to clipboard', 3e3)),
        finalize(() => this.clearClipBoardSubscriber())
      ).subscribe();
  }

  private clearModalSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }

  private clearClipBoardSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }
}
