import { NgModule } from '@angular/core';
import { SubjectAreasComponent } from './components/subject-areas.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    SubjectAreasComponent
  ],
  exports: [
    SubjectAreasComponent
  ],
  providers: []
})
export class SubjectAreasModule { }
