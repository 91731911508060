import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';


@Directive({
  selector: 'edit-user-avatar-new',
})
export class EditUsersAvatarDirective extends UpgradeComponent {
  static readonly selector = 'editUserAvatarNew';

  @Input() user: {id: number, firstName?: string, lastName?: string, title?:string};

  constructor(elementRef: ElementRef, injector: Injector) {
    super(EditUsersAvatarDirective.selector, elementRef, injector);
  }
}
