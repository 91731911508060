<form #generateAppKeyForm="ngForm" class="no-margin-bottom" (ngSubmit)="generateKey(generateAppKeyForm.form)">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">API key</h4>
  </div>

  <div class="modal-body">
    <div class="form-group"
         [ngClass]="{'has-error': ngExpirationDate.invalid && ngExpirationDate.dirty}">
      <label [for]="'expirationDate'" class="control-label">Expiration date</label>
      <datepicker [id]="'expirationDate'" name="expirationDate" placeholder="MM/DD/YYYY"
                  [(ngModel)]="expirationDate"
                  [minDate]="currentDate"
                  #ngExpirationDate="ngModel"></datepicker>
      <p class="help-block" *ngIf="ngExpirationDate.invalid && ngExpirationDate.dirty">
        <span *ngIf="ngExpirationDate.errors?.ngbDate?.invalid">
          The date is incorrect. Correct format is "mm/dd/yyyy".
        </span>
        <span *ngIf="ngExpirationDate.errors?.ngbDate?.minDate">
          The date is incorrect. The date should be greater than the current date.
        </span>
      </p>
    </div>

  </div>

  <div class="modal-footer">
    <fieldset [disabled]="loading" class="no-margin-bottom">
      <button type="button" class="btn btn-warning" title="Cancel" (click)="closeModal()">Cancel</button>
      <button type="submit" class="btn btn-success" title="Generate" data-spinner-color="#000"
              [disabled]="loading" [ladda]="loading">
        Generate
      </button>
    </fieldset>
  </div>
</form>
