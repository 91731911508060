<span *ngFor="let item of filter.selectedItems">
  <a href="#" class="label label-tag" title="{{getText(item)}}"
     (click)="applyFilter(item.value, item.term)"
     *ngIf="filter.visible && !filter.combineLabels && filter.name !== 'CoursePriceFilter'"
     [ngClass]="{'no-capitalize':getText(item)==='eKnowledge'}">
    <span [innerHtml]="getText(item)"></span>
    <selected-filter-rating [item]="item" *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
    <span class="fa fa-times"></span>
  </a>
</span>

<!--Custom tags for price filter CoursePriceFilter-->
<a href="#" class="label label-tag" title="Course Price"
   (click)="(isNumber(filter.items[0].value) && applyFilter(filter.items[0].value, filter.items[0].term)) ||
             (isNumber(filter.items[1].value) && applyFilter(filter.items[1].value, filter.items[1].term))"
   *ngIf="filter.name === 'CoursePriceFilter' && filter.visible && (isNumber(filter.items[0].value) ||
            isNumber(filter.items[1].value))">
  <span *ngIf="isNumber(filter.items[0].value)" [innerHtml]="getText(filter.items[0])"></span>
  <span *ngIf="isNumber(filter.items[1].value)" [innerHtml]="getText(filter.items[1])"></span>
  <span class="fa fa-times"></span>
</a>

<a href="#" class="label label-tag" title="Course Price"
   (click)="applyFilter(filter.items[2].value, filter.items[2].term)"
   *ngIf="filter.name === 'CoursePriceFilter' && filter.visible && filter.items[2].value">
  <span [innerHtml]="getText(filter.items[2])"></span>
  <span class="fa fa-times"></span>
</a>

<a href="#" class="label label-tag" [title]="getTitle()" (click)="clearSelection()"
   *ngIf="filter.visible && filter.combineLabels && filter.selectedItems.length > 0">
  {{combineTextFormatter()}}
  <span class="fa fa-times"></span>
</a>
