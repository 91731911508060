import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import {
  ISearchFilter, ISearchFilterConfig, ISearchFilterService,
  ISearchFilterServices,
  ISearchFilterTerm
} from 'modules/search/models/search-filters.models';
import { SearchFiltersMappingService } from './search-filter-mapping.service';
import { CurrentUserService } from 'core/authorization';
import { IUser } from 'modules/user/models/user.model';


@Injectable()
export class SearchFiltersProviderService {
  private user: IUser;

  constructor(
    private globalConfig: GlobalConfig,
    private filterMapper: SearchFiltersMappingService,
    currentUser: CurrentUserService
  ) {
    this.user = currentUser.get();
  }

  get sortTerms(): ISearchFilterTerm[] {
    return this.globalConfig.settings.search.sortItems;
  }

  get filteringTerms(): ISearchFilterTerm[] {
    return this.globalConfig.settings.search.terms;
  }

  loadFilters(): ISearchFilterServices | null {
    const filterServices = this.getFiltersServices();

    filterServices.all.forEach((provider) => {
      if (!provider.filter.disabled) {
        provider.load?.();
      }
    });

    return filterServices;
  }

  private getFiltersServices(): ISearchFilterServices {
    const uiFilters: ISearchFilterService[] = [];
    const advancedFilters: ISearchFilterService[] = [];
    const allFilters = new Map<string, ISearchFilterService>();
    const items = this.globalConfig.settings.search.filters;

    for (let i = 0; i < items?.length; i++) {
      const item = items[i];
      const provider = this.filterMapper.get(item.name);

      if (provider) {
        if (item.options) {
          Object.assign(provider.filter, item.options);
        }

        if (item.associateWith) {
          this.associateWith(item, provider.filter, allFilters.get(item.associateWith.filter).filter);
        }

        if (item.roles) {
          provider.filter.disabled = !this.user.isInRole(item.roles);
        }

        if (item.ui) {
          uiFilters.push(provider);
        } else if (item.advanced) {
          advancedFilters.push(provider);
        }

        provider.init?.();
        allFilters.set(item.name, provider);
      }
    }

    return { ui: uiFilters, advanced: advancedFilters, all: Array.from(allFilters.values()) };
  }

  private associateWith(options: ISearchFilterConfig, filter: ISearchFilter, parent?: ISearchFilter | null) {
    filter.associateWith = {
      ...filter.associateWith,
      filter: parent,
      conditions: options.associateWith.conditions,
    };

    parent.associateWith = {
      ...parent.associateWith,
      dependents: [...parent.associateWith?.dependents || [], filter]
    };
  }
}
