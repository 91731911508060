import { Component, Input } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { HttpParams } from '@angular/common/http';
import { ISearchFilterServices } from 'modules/search/models/search-filters.models';


@Component({
  selector: 'search-mobile-action-panel',
  templateUrl: './search-mobile-action-panel.component.html'
})
export class SearchMobileActionPanelComponent {
  @Input() filters: ISearchFilterServices;
  @Input() hideExport?: boolean;
  @Input() viewMode?: string;
  @Input() customFilters?: boolean;

  constructor(
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private window: Window
  ) { }

  chooseFilters() {
    const params = Object.assign({
      returnState: this.activeState.current.name || 'main.search',
    }, this.urlService.search());

    if (this.customFilters) {
      Object.assign(params, {
        customFilters: this.filters.ui.filter(s => s.filter.visible).map(s => s.filter.name)
      });
    }

    this.stateService.go('edit.searchFilters', params);
  }

  exportSearchResult() {
    let params = new HttpParams();

    params = params.appendAll(this.urlService.search());
    this.window.open(params.keys().length ? '/a/search/export/?' + params : '/a/search/export/');
  }
}
