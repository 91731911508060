<div *ngIf="filter.visible && filter.items.length > 0">
  <div ngbAccordion *ngIf="!fullScreen" class="search-filter-group">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{filter.label}}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <form #dateForm="ngForm" name="dateForm" [ngClass]="{'has-error': dateForm.invalid}">
              <div *ngFor="let item of filter.items" class="form-group">
                <label>{{item.text}}</label>
                <div class="input-group">
                  <datepicker [id]="item.term" [name]="item.term" placeholder="MM/DD/YYYY"
                              (ngModelChange)="applyFilter($event, item.term);"
                              [ngModel]="item.value"
                              #dateInput="ngModel" #datePicker='datepicker'></datepicker>
                </div>
                <p class="help-block"
                   [hidden]="!(dateInput.invalid && dateInput.dirty)">
                  <span [hidden]="!dateInput.errors?.parse">The date format is mm/dd/yyyy.</span>
                </p>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <ul class="content-list">
      <li class="content-list-item search-item"
          [ngClass]="{'search-item-selected':item.selected}" *ngFor="let item of filter.items">

        <div class="date-item">
          <form #dateForm='ngForm' name="dateForm" class="form-group no-margin-bottom"
                [ngClass]="{'has-error': dateForm.invalid}">
            <label>{{item.text}}</label>
            <div class="input-group">
              <datepicker [id]="item.term" [name]="item.term" placeholder="MM/DD/YYYY"
                          (ngModelChange)="applyFilter($event, item.term);"
                          [(ngModel)]="item.value"
                          #dateInput="ngModel" #datePicker='datepicker'></datepicker>
            </div>
            <p class="help-block"
               [hidden]="!(dateInput.invalid && dateInput.dirty)">
              <span [hidden]="!dateInput.errors?.parse">The date format is mm/dd/yyyy.</span>
            </p>
          </form>
        </div>
      </li>
    </ul>
  </div>
</div>
