import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUrlParams } from 'core/navigation/models/navigation-url.model';
import { ElmsUtils } from 'core/utils';
import { ISavedSearchItem } from 'modules/search/models/saved-searches.models';
import { map, Observable } from 'rxjs';


enum SavedSearchEndpoints {
  GET = '/a/user/:userId/saved_search/:savedSearchId/',
  UPDATE = '/a/user/saved_search/:savedSearchId/',
  POST = '/a/user/saved_search/',
  QUERY = '/a/user/:userId/saved_search/',
  DELETE = '/a/user/saved_search/:savedSearchId/'
}

@Injectable()
export class SavedSearchesServive {
  constructor(private http: HttpClient) { }

  get(userId: number, savedSearchId: number): Observable<ISavedSearchItem> {
    return this.http.get<ISavedSearchItem>(ElmsUtils.formatUrl(SavedSearchEndpoints.GET, { userId, savedSearchId }))
      .pipe(map(item => this.transform(item)));
  }

  query(userId: number): Observable<ISavedSearchItem[]> {
    return this.http.get<ISavedSearchItem[]>(ElmsUtils.formatUrl(SavedSearchEndpoints.QUERY, { userId }))
      .pipe(map(response => response.map(i => this.transform(i))));
  }

  update(savedSearchId: number, payload: Partial<ISavedSearchItem>): Observable<ISavedSearchItem> {
    return this.http.put<ISavedSearchItem>(
      ElmsUtils.formatUrl(SavedSearchEndpoints.UPDATE, { savedSearchId }),
      payload
    ).pipe(map(item => this.transform(item)));
  }

  save(payload: Partial<ISavedSearchItem>): Observable<ISavedSearchItem> {
    return this.http.post<ISavedSearchItem>(SavedSearchEndpoints.POST, payload)
      .pipe(map(item => this.transform(item)));
  }

  delete(savedSearchId: number): Observable<null> {
    return this.http.delete<null>(ElmsUtils.formatUrl(SavedSearchEndpoints.DELETE, { savedSearchId }));
  }

  private transform(item: ISavedSearchItem): ISavedSearchItem {
    const params = new URLSearchParams(item.searchString);
    const urlParams: IUrlParams = {};

    params.forEach((value, key) => {
      urlParams[key] = Array.isArray(value) || !value.includes(',') ? value : value.split(',');
    });

    return {
      ...item,
      params: urlParams,
    };
  }
}
