<div>
  <div class="mobile-filters-bar" *ngIf="viewMode !=='button'">
    <button class="btn btn-link" type="button" (click)="chooseFilters()">
      <i class="fa fa-filter xs-margin-right" aria-hidden="true"></i>
      {{ filters?.ui.length ? 'Filter & Sort' : 'Sort'}}
    </button>

    <button class="btn btn-link" type="button" (click)="exportSearchResult()" *ngIf="!hideExport">
      <i class="fa fa-download xs-margin-right" aria-hidden="true"></i> Export
    </button>
  </div>

  <div *ngIf="viewMode === 'button'">
    <button class="btn btn-block btn-outline" type="button" (click)="chooseFilters()">
      <i class="fa fa-filter" aria-hidden="true"></i> {{ filters?.ui.length ? 'Filter & Sort' : 'Sort'}}
    </button>
  </div>
</div>
