import { CompetencyFilterComponent } from './modal/competency-filter.component';
import { SessionLabelFilterComponent } from './modal/session-label-filter.component';
import { CourseAttributeFilterComponent } from './modal/course-attribute-filter.component';
import { GroupTagsFilterComponent } from './modal/group-tags-filter.component';
import { Type } from '@angular/core';


export type IFilterComponentsMap =
  | CompetencyFilterComponent
  | SessionLabelFilterComponent
  | GroupTagsFilterComponent
  | CourseAttributeFilterComponent;

export const filterMapping: Record<string, Type<IFilterComponentsMap>> = {
  'competencyFilter': CompetencyFilterComponent,
  'sessionLabelFilter': SessionLabelFilterComponent,
  'groupTagsSearchFilter': GroupTagsFilterComponent,
  'courseAttributeFilter': CourseAttributeFilterComponent
};
