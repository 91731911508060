import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiAccessService } from 'modules/user/external-applications/api-v1/services/api-access.service';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'api-v1-key-edit-confirmation-modal',
  templateUrl: 'api-v1-key-edit-confirmation-modal.component.html'
})
export class ApiV1KeyEditConfirmationModalComponent {
  @Input() id: number;
  password: string;
  loading = false;
  error: string;

  private requestSubscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private apiAccessService: ApiAccessService,
  ) {}

  closeModal(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.loading = true;
    this.error = null;

    this.requestSubscriber = this.apiAccessService
      .generateAuthorizationToken(this.id, this.password, true)
      .pipe(
        tap(response => {
          this.activeModal.close(response);
        }),
        catchError((reason: HttpErrorResponse) => {
          this.error = reason.message || reason.error;

          return EMPTY;
        }),
        finalize(() => {
          this.loading = false;
          this.clearRequestSubscriber();
        })
      ).subscribe();
  }

  private clearRequestSubscriber(): void {
    this.requestSubscriber?.unsubscribe();
    delete this.requestSubscriber;
  }
}
