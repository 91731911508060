import { Component, Input, OnInit } from '@angular/core';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';


@Component({
  selector: 'boolean-filter',
  templateUrl: './boolean-filter.component.html',
})
export class BooleanFilterComponent implements OnInit {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  filter: ISearchFilter;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
  }

  applyFilter(value: string): boolean {
    return this.filterService.applyFilter(value);
  }
}
