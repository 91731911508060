import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { map, Observable, of, tap } from 'rxjs';
import {
  ISearchContentType,
  ISearchItem,
  ISearchQueryParams,
  ISearchResponse,
  ISearchService,
} from '../models/search.models';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum DefaultSearchEndpoints {
  GET = '/a/search/',
}

@Injectable()
export class SearchService<T extends ISearchItem = ISearchItem> implements ISearchService<T> {
  static readonly endpoint: string;

  initialRequestItemCount = 80;
  sequentRequestItemsCount = 20;
  method: 'GET' | 'POST' = 'GET';
  type: string | string[];

  readonly mode: string = 'public';

  protected requestParams: ISearchQueryParams;

  private lastRequestItemCount: number;

  constructor(protected http: HttpClient) {}

  search(params: ISearchQueryParams, throwHttpErrors = false): Observable<ISearchResponse<T>> {
    this.lastRequestItemCount = this.initialRequestItemCount;
    this.requestParams = Object.assign({}, this.defaultParams, _.omitBy(this.processQuery(params), _.isNil));

    if (this.method === 'POST') {
      return this.http.post<ISearchResponse<T>>(
        this.endpoint, _.mapValues(this.requestParams, v => String(v)),
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
      ).pipe(map(response => this.transform(response)));
    }

    return this.http.request<ISearchResponse<T>>(
      this.method, this.endpoint,
      { params: this.requestParams, context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    ).pipe(map(response => this.transform(response)));
  }

  searchNext(throwHttpErrors = false): Observable<ISearchResponse<T>> {
    if ('startDoc' in this.requestParams) {
      this.requestParams.startDoc += this.lastRequestItemCount;
      this.requestParams.count = this.sequentRequestItemsCount;
      this.lastRequestItemCount = this.sequentRequestItemsCount;
    }

    return this.http.get<ISearchResponse<T>>(
      this.endpoint,
      { params: this.requestParams, context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  getPage(value: number, throwHttpErrors = false): Observable<ISearchResponse<T>> {
    if (this.requestParams && this.requestParams['startDoc']) {
      this.requestParams.startDoc = value * this.sequentRequestItemsCount;
      this.requestParams.count = this.sequentRequestItemsCount;
      this.lastRequestItemCount = this.sequentRequestItemsCount;
    }

    return this.http.get<ISearchResponse<T>>(
      this.endpoint,
      { params: this.requestParams, context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  getContentTypes(throwHttpErrors = false): Observable<ISearchContentType[]> {
    const sessionStorageKey = `objectTypes.${this.mode}`;
    const contentTypes = JSON.parse(sessionStorage.getItem(sessionStorageKey)) as ISearchContentType[];

    if (!contentTypes) {
      // Announcements are not supported yet.
      return this.http.get<ISearchContentType[]>(
        `${this.endpoint}content_types/`,
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
      ).pipe(
        map(t => t.filter(i => i.value !== 'announcement')),
        map(t => t.map(i => ({ name: i.name, value: i.value, term: 'type' }))),
        tap(t => sessionStorage.setItem(sessionStorageKey, JSON.stringify(t)))
      );
    }

    return of(contentTypes);
  }

  getExportLink(): string {
    const searchQuery = _.omit<Partial<ISearchQueryParams>>(this.requestParams, ['count', 'page', 'rows', 'startDoc']);
    const queryParams = new URLSearchParams(searchQuery as Record<string, string>).toString();

    return `${this.endpoint}export/?${queryParams}`;
  }

  protected get endpoint(): string {
    return DefaultSearchEndpoints.GET;
  }

  protected get defaultParams(): ISearchQueryParams {
    return Object.assign({
      startDoc: 0,
      count: this.lastRequestItemCount
    }, _.isNil(this.type) ? {} : { type: this.type });
  }

  protected transform(response: ISearchResponse<T>): ISearchResponse<T> {
    return {
      ...response,
      items: response.items.map(i => ({ ...i, id: Number(i.id) })),
    };
  }

  /** @deprecated Is used only for `AngularJS` style. */
  private processQuery(params: ISearchQueryParams | null): ISearchQueryParams | null {
    if (params) {
      if ('showInactive' in params) {
        const showInactive = Boolean(params.showInactive);

        if (!showInactive) {
          params.active = true;
        }

        delete params.showInactive;
      }

      if ('showExpired' in params) {
        params.expired = Boolean(params.showExpired);

        delete params.showExpired;
      }

      if ('showUnpublished' in params) {
        const showUnpublished = Boolean(params.showUnpublished);

        params.published = !showUnpublished;
        delete params.showUnpublished;
      }
    }

    return params;
  }
}
