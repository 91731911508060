import { Injectable } from '@angular/core';
import { IQuestion, IQuizResult } from 'modules/quiz/models/quiz.model';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { QuizQuestionType, QuizResultStatus } from 'modules/quiz';


enum QuizResultEndpoints {
  GET = '/a/quizzes/results/:resultId/',
  ANSWER = '/a/quizzes/results/:resultId/question_answer/',
  START = '/a/quizzes/results/:resultId/start/',
  RESULT = '/a/quizzes/results/:resultId/',
  TIME_TRACKING = '/a/quizzes/results/:resultId/time_tracking/',
}

@Injectable()
export class QuizResultService {
  constructor(private http: HttpClient) { }

  public get(resultId:number): Observable<IQuizResult> {
    return this.http.get<IQuizResult>(
      ElmsUtils.formatUrl(QuizResultEndpoints.GET, { resultId }),
    );
  }

  public saveQuestionAnswer(resultId:number, question: IQuestion): Observable<IQuizResult> {
    return this.http.put<IQuizResult>(
      ElmsUtils.formatUrl(QuizResultEndpoints.ANSWER, { resultId }),
      question,
    );
  }

  public start(resultId:number): Observable<IQuizResult> {
    return this.http.put<IQuizResult>(
      ElmsUtils.formatUrl(QuizResultEndpoints.START, { resultId }),
      {},
    );
  }

  public finish(quizResult:IQuizResult, handleQuizResult?:boolean): Observable<IQuizResult> {
    const params = handleQuizResult ? { handleQuizResult } : {};

    return this.http.put<IQuizResult>(
      ElmsUtils.formatUrl(QuizResultEndpoints.RESULT, { resultId: quizResult.id }),
      params,
    ).pipe(
      tap((result) => {
        Object.assign(quizResult, result);
      }));
  }

  public timeTracking(resultId:number): Observable<IQuizResult> {
    return this.http.put<IQuizResult>(
      ElmsUtils.formatUrl(QuizResultEndpoints.TIME_TRACKING, { resultId }),
      {},
    );
  }

  public isCompleted(quizResult:IQuizResult): boolean {
    return quizResult && (quizResult.statusId === QuizResultStatus.passed ||
      quizResult.statusId === QuizResultStatus.failed ||
      quizResult.statusId === QuizResultStatus.completed ||
      quizResult.statusId === QuizResultStatus.pending);
  }

  public pending(quizResult:IQuizResult): boolean {
    return quizResult && quizResult.statusId === QuizResultStatus.pending;
  }

  public questionHasAnswer(question:IQuestion): boolean {
    if (question.optional) {
      return true;
    }

    if ([QuizQuestionType.checkAllThatApply, QuizQuestionType.multiChoice].includes(question.typeId)) {
      return question.answerVariants.some((variant) => {
        return variant.selected && (!variant.otherRequired || (variant.otherRequired && variant.other));
      });
    }

    if (question.typeId === QuizQuestionType.shortAnswer) {
      return !!question.answer;
    }

    if (question.typeId === QuizQuestionType.matching) {
      return !question.answerVariants.some((o) => !o.answerText);
    }

    if (question.typeId === QuizQuestionType.fillin) {
      return !question.answerVariants.some((o) => !o.answerText);
    }

    return true;
  }
}
