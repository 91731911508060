import { NgModule } from '@angular/core';
import { UsersAvatarDirective } from 'modules/user/view/components/user-avatar.component.upgraded';
import { UnknownUserAvatarComponent } from 'modules/user/view/components/unknown-user-avatar.component';
import { UserService } from './services/user.service';
import { EditUserModule } from './edit/edit-user.module';
import { UserEmailOptInService } from 'modules/user/services/user-email-opt-in.service';
import { UserRolesService } from 'modules/user/services/user-roles.service';
import { UserGroupsService } from 'modules/user/services/user-groups.service';


@NgModule({
  imports: [EditUserModule],
  declarations: [
    UsersAvatarDirective,
    UnknownUserAvatarComponent
  ],
  exports: [
    UsersAvatarDirective,
    UnknownUserAvatarComponent
  ],
  providers: [
    UserEmailOptInService,
    UserGroupsService,
    UserService,
    UserRolesService
  ]
})
export class UserModule { }
