import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { EMPTY, Observable, Subject, Unsubscribable, catchError, finalize, from, lastValueFrom, map } from 'rxjs';
import {
  IAchievementProgress, IUserAchieved, ILeaderboardUserStats, ILeaderboardUsers
} from '../models/user-achievements.models';
import { IGroupAchievement } from 'modules/admin/group/achievements/models/group-achievements.model';
import { AchievementDetailsComponent } from '../components/achievement-details.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { IUser } from 'modules/user/models/user.model';


export enum UserAchievementsEndpoints {
  GET_USER = '/a/user/:id/',
  GET_ACHIEVEMENTS = '/a/user/:id/achievements/',
  GET_AVAILABLE = '/a/user/:id/achievements/available/',
  GET_STATS = '/a/user/:id/achievements/stats/',
  GET_LEADERBOARD = '/a/user/:id/achievements/leaderboard/',
  GET_PROGRESS = '/a/user/:id/achievements/:achievId/progress/',
}

@Injectable()
export class UserAchievementsService {
  modalSubscriber?: Unsubscribable;

  loading = new Subject<any>();

  constructor(
    private http: HttpClient,
    private ngbModalService: NgbModal,
    private notificationService: NotificationService,
  ) {}

  getUser(id: number): Observable<IUser> {
    return this.http.get<any>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_USER, { id })
    ).pipe(map(response => response.user));
  }

  getAchievements(id: number): Observable<IUserAchieved[]> {
    return this.http.get<any>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_ACHIEVEMENTS, { id })
    ).pipe(map(response => response.items));
  }

  getAvailable(id: number): Observable<IGroupAchievement[]> {
    return this.http.get<IGroupAchievement[]>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_AVAILABLE, { id })
    );
  }

  getStats(id: number): Observable<ILeaderboardUserStats> {
    return this.http.get<any>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_STATS, { id })
    ).pipe(map(response => response.stat));
  }

  getLeaderboard(id: number, params: any): Observable<ILeaderboardUsers> {
    return this.http.get<ILeaderboardUsers>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_LEADERBOARD, { id }), { params }
    );
  }

  getProgress(id: number, achievId: number): Observable<IAchievementProgress> {
    return this.http.get<IAchievementProgress>(
      ElmsUtils.formatUrl(UserAchievementsEndpoints.GET_PROGRESS, { id, achievId })
    );
  }

  updateAchievementsForUser(
    userAchievements: IUserAchieved[],
    availableAchievements: IGroupAchievement[]
  ): IGroupAchievement[] {
    return availableAchievements.map(achievement => {
      if (this.checkAchievement(achievement, userAchievements)) {
        const index = userAchievements.findIndex(el => el.objectData.groupAchievementId === achievement.id);

        achievement.achieved = userAchievements[index];
      }

      return achievement;
    });
  }

  checkAchievement(achievement: IGroupAchievement, userAchievements: IUserAchieved[]): boolean {
    switch (achievement.achievementTypeId) {
      case 10:
      case 9:
      case 8:
      case 7:
        return userAchievements.some(el => el.objectData.groupAchievementId === achievement.id);
      default:
        return false;
    }
  }

  async getLeaderboardUsers(stats: ILeaderboardUserStats): Promise<ILeaderboardUsers> {
    const take = 5;
    const userPage = stats ? (Math.floor(stats.rowNumber / take) + (stats.rowNumber % take ? 1 : 0)) : null;
    const users: ILeaderboardUsers = {
      count: 0,
      items: [],
      divider: userPage > 2,
    };

    if (stats) {
      if (userPage > 2) {
        const chank1 = await lastValueFrom(
          this.getLeaderboard(stats.user.id, { offset: 0, take })
        );

        users.count = chank1.count;
        users.items.push(...chank1.items);
        const offset = (userPage - 1) * take;
        const chank2 = await lastValueFrom(
          this.getLeaderboard(stats.user.id, { offset, take })
        );

        users.items.push(...chank2.items);

        return users;
      } else {
        const response = await lastValueFrom(
          this.getLeaderboard(stats.user.id, { offset: 0, take: take * 2 })
        );

        users.count = response.count;
        users.items.push(...response.items);

        return users;
      }
    } else {
      return null;
    }
  }

  openModal(user: IUser, achievement: IGroupAchievement): void {
    this.getProgress(user.id, achievement.id).subscribe(progress => {
      this.showDetails(user, achievement, progress);
      this.loading.next(false);
    }, () => {
      this.notificationService.error('Badge details are not available', 5e3);
    });
  }

  showDetails(user: IUser, achievement: IGroupAchievement, progress: IAchievementProgress): void {
    const modalReference: NgbModalRef = this.ngbModalService.open(AchievementDetailsComponent, {
      backdrop: true,
      windowClass: 'achievements-details-wrapper',
    });

    const component = (<AchievementDetailsComponent>modalReference.componentInstance);

    component.achievement = achievement;
    component.progress = progress;
    component.userId = user.id;

    this.modalSubscriber = from(modalReference.result)
      .pipe(
        catchError(() => EMPTY),
        finalize(() => this.clearModalSubscriber()),
      ).subscribe();
  }

  clearModalSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }
}
