import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { ParamDeclaration } from '@uirouter/core/lib/params/interface';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { searchTerms } from 'modules/search/common';
import { BaseFilterService } from 'app/deprecated/search/services/filters-old/search-filtering.service.ajs-upgraded';
import { FullScreenFilterViewComponent } from './common/components/filtering/full-screen-filter-view.component';
import { SearchComponent } from './components/search.component';


export const searchRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.search',
    url: '^/search?' + searchTerms.join('&'),
    params: {
      ...searchTerms.reduce((obj: Record<string, ParamDeclaration>, key) => {
        obj[key] = {
          value: null,
          dynamic: true,
        };

        return obj;
      }, {}),
      query: {
        type: 'string',
        value: null, // <-- Default value
        dynamic: true,
        array: false,
      },
    },
    component: SearchComponent,
    data: {
      label: 'Search',
      availableFor: ['regularUser', 'admin'],
    },
  },
  {
    name: 'edit.searchFilters',
    url: '^/search-filters?' + searchTerms.join('&') + '&customFilters&returnState&catalogType',
    params: [...searchTerms, 'customFilters', 'returnState', 'catalogType'].reduce((obj, st) => {
      obj[st] = { value: null, dynamic: true };

      return obj;
    }, {}),
    data: {
      label: 'Search Filters',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
    component: FullScreenFilterViewComponent,
    resolve: [
      {
        provide: 'filters',
        deps: [Transition, BaseFilterService, GlobalConfig],
        useFactory: (transition: Transition, searchFilters: BaseFilterService, globalConfig: GlobalConfig) => {
          let filtersData: string[] = null;

          if (transition.params().returnState === 'main.fullCatalog') {
            filtersData = globalConfig.settings.searchFilters.catalog.filter((f) => f.available).map((f) => f.name);
          }

          if (filtersData && transition.params().customFilters && transition.params().customFilters.length) {
            const customFilters = Array.isArray(transition.params().customFilters)
              ? transition.params().customFilters
              : [transition.params().customFilters];
            const availableFilters = _.intersection(filtersData, customFilters);
            const filtersAll = _.map(availableFilters, (name: string) => {
              const filter = searchFilters.createFilter(name);

              filter.load();

              return filter;
            });

            return {
              ui: filtersAll,
              all: filtersAll,
            };
          } else {
            return searchFilters.loadFilters();
          }
        },
      },
      {
        provide: 'returnState',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().returnState;
        },
      },
    ],
  },
];
