import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IGroupShortInfo } from 'modules/group/models/group.model';
import { IOrganization } from 'modules/organizations/models/organization.model';
import _ from 'lodash';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


enum UserGroupsServiceEndpoints {
  GET = '/a/user/:userId/groups/',
  POST = '/a/user/:userId/groups/',
  DELETE = '/a/user/:userId/groups/:groupId/',
  MY_ADMIN_GROUPS = '/a/user/my_admin_groups/',
  ORGANIZATIONS = '/a/user/:userId/organizations/'
}


@Injectable()
export class UserGroupsService {
  constructor(private http: HttpClient) {}

  // Equivalent to query: GET request to get all groups for a specific user
  query(userId: number): Observable<any[]> {
    return this.http.get<any[]>(ElmsUtils.formatUrl(UserGroupsServiceEndpoints.GET, { userId: userId }));
  }

  // Equivalent to save: POST request to save groups for a user
  save(userId: number, groups: any[]): Observable<any[]> {
    return this.http.post<any[]>(ElmsUtils.formatUrl(UserGroupsServiceEndpoints.POST, { userId: userId }), groups);
  }

  // Equivalent to delete: DELETE request to remove a specific group for a user
  delete(userId: number, groupId: number): Observable<void> {
    return this.http.delete<void>(ElmsUtils.formatUrl(UserGroupsServiceEndpoints.DELETE,
      { userId: userId, groupId: groupId }));
  }

  getManagedGroups(roles: number[]): Observable<IGroupShortInfo[]> {
    let params = new HttpParams();

    roles.forEach(role => {
      params = params.append('roles', role);
    });

    return this.http.get<IGroupShortInfo[]>(UserGroupsServiceEndpoints.MY_ADMIN_GROUPS,
      { params, context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE) })
      .pipe(
        // Using lodash's uniqBy to ensure uniqueness based on group.id
        map(response => _.uniqBy(response, 'id'))
      );
  }

  getOrganizations(userId: number, throwHttpErrors?: boolean): Observable<IOrganization[]> {
    const context = new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE);

    if (throwHttpErrors) {
      context.set(PASS_HTTP_ERRORS_TOKEN, true);
    }

    return this.http.get<any[]>(ElmsUtils.formatUrl(UserGroupsServiceEndpoints.ORGANIZATIONS, { userId: userId }),
      { context: context });
  }
}
