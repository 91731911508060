<div *ngIf="userAchievements.length">
  <h4>Earned Badges</h4>
    <div class="d-flex items-center achievement-scales">
      <div class="achievement-image-col" *ngFor="let achievement of userAchievements.slice(0, 3)">
        <a href="#" (click)="showDetails(achievement)" [attr.title]="'Open ' + achievement.message + ' details'"
          [attr.id]="trackingName + '_show_badge_details'">
          <img [src]="'/a/media/file/' + achievement.badgeFile.id + '/?rnd=' + achievement.badgeFile.rnd"
            alt="'{{achievement.message}}' badge" class="achievement-image">
        </a>
      </div>
      <div class="achievement-image-col"  *ngIf="userAchievements.length > maxCount">
        <a href="#" class="no-text-decoration"
          [uiSref]="'main.user_achievements'"
          [uiParams]="{userId: user.id}"
          [attr.id]="trackingName + '_show_all_badges'">
          <div class="achievement-total" placement="bottom" ngbTooltip="View All">
            <span class="sr-only">All achievements</span>
            <span class="achievements-total-count" aria-hidden="true">
              <span *ngIf="userAchievements.length <= 99">+{{userAchievements.length - maxCount}}</span>
              <span *ngIf="userAchievements.length > 99">99+</span>
            </span>
          </div>
        </a>
      </div>
      <div *ngIf="stats?.totalPoints" class="ml-auto">
        <span class="label label-primary" >{{stats.totalPoints}} points</span>
      </div>
    </div>
</div>
