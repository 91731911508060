import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ResourceService } from './services/resource.service';
import { ResourceCategoryService } from './services/resource-category.service';
import { ResourceCategoryPathComponent } from './view/categories/category-path.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { resourcesRoutingModule } from './resources-routing.module';
import { ResourceDetailsComponent } from './view/resource-details.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    UIRouterUpgradeModule.forChild({ states: resourcesRoutingModule }),
    LmsComponentsModule
  ],
  declarations: [
    ResourceCategoryPathComponent,
    ResourceDetailsComponent
  ],
  providers: [
    HttpClient,
    CookieService,
    ResourceService,
    ResourceCategoryService
  ],
  exports: [
    ResourceCategoryPathComponent
  ]
})
export class ResourcesModule {}
