<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">New Saved Search</h4>
</div>

<form #saveSearchForm="ngForm" (ngSubmit)="submit()" name="saveSearchForm">
  <div class="modal-body">
    <div class="form-group">
      <div for="searchTitle" class="no-margin-bottom">
        <input type="text" id="searchTitle" class="form-control required" name="searchTitle"
               placeholder="Please enter a name for your new saved search"
               maxlength="500" required noWhiteSpace autoFocus
               [(ngModel)]="title">
      </div>
    </div>
    <div class="form-group">
      <label>
        <input type="checkbox" class="" name="notify" [(ngModel)]="notify"> Email me if new courses are added
        that match this saved search
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" name="Cancel" class="btn btn-warning" [disabled]="processing"
            (click)="close()"> Cancel</button>
    <button type="submit" name="Save" class="btn btn-success" data-spinner-color="#000" [ladda]="processing"
            [disabled]="saveSearchForm.invalid"> Add New</button>
  </div>
</form>
