import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';


@Injectable()
export class TagSearchFilterService extends BaseSearchFilterService {
  public filter: ISearchFilter = {
    name: 'tagFilter',
    label: 'Tag',
    term: 'tag',
    mode: 'singleChoice',
    multiChoice: false,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  protected initSelectedItems(): void {
    super.initSelectedItems();

    this.filter.selectedItems = Array.from(this.filter.selectedOptions.values()).map(i => ({ value: i, text: i }));
  }
}
