import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import { IUrlParams, OnUrlParamsChange } from 'core/navigation/models/navigation-url.model';
import { CourseService } from 'modules/course/common/services/course.service';
import { ISearchFilterChanges } from 'modules/search/models/search-filters.models';
import { ISearchContentType } from 'modules/search/models/search.models';
import { SearchService } from 'modules/search/services';
import { Observable, mergeMap, map, of, tap } from 'rxjs';


@Component({
  selector: 'extended-object-filter',
  templateUrl: './extended-object-filter.component.html',
})
export class ExtendedObjectFilterComponent implements OnInit, OnUrlParamsChange {
  @Input() disabled?: boolean;
  @Output() filterChanged = new EventEmitter<ISearchFilterChanges>();

  items: ISearchContentType[];
  selected: ISearchContentType = null;

  private defaultChanges: ISearchFilterChanges;

  constructor(
    private urlService: UrlParamService,
    private searchService: SearchService,
    private courseService: CourseService
  ) { }

  ngOnInit(): void {
    this.fetchData().subscribe(data => {
      this.items = data;
      this.defaultChanges = Object.fromEntries<null>(data.map(i => [i.term, null]));

      if (data.some(i => ['label_id', 'format_id'].includes(i.term))) {
        this.defaultChanges['type'] = null;
      }

      this.ngOnUrlParamsChange(this.urlService.urlParams);
    });

    this.urlService.subscribe((p) => this.ngOnUrlParamsChange(p));
  }

  ngOnUrlParamsChange(params: IUrlParams): void {
    this.selected = this.items?.find(i => i.term in params && params[i.term] === i.value) || null;
  }

  applyFilter(item: ISearchContentType | null) {
    if (item) {
      this.filterChanged.emit({
        ...this.defaultChanges,
        'type': ['label_id', 'format_id'].includes(item.term) ? 'course' : null,
        [item.term]: item.value,
      });
    } else {
      this.filterChanged.emit(this.defaultChanges);
    }
  }

  private fetchData(): Observable<ISearchContentType[]> {
    const sessionStorageKey = `extendedObjectTypes`;
    const contentTypes = JSON.parse(sessionStorage.getItem(sessionStorageKey)) as ISearchContentType[];

    if (!contentTypes) {
      return this.searchService.getContentTypes()
        .pipe(
          mergeMap(types => {
            if (types.some(i => i.value === 'course')) {
              return this.courseService.getCourseOptions()
                .pipe(
                  map(options => {
                    const items = types.filter(i => i.value !== 'course');

                    if (options.labels?.length) {
                      return items.concat(
                        options.labels.map(i => ({ name: i.name, term: 'label_id', value: i.id.toString() })),
                        { name: 'Unassigned', term: 'label_id', value: 'unassigned' }
                      );
                    }

                    return items.concat(
                      options.formats.map(i => ({ name: i.name, term: 'format_id', value: i.id.toString() }))
                    );
                  })
                );
            }

            return of(types);
          }),
          tap(types => sessionStorage.setItem(sessionStorageKey, JSON.stringify(types))),
        );
    }

    return of(contentTypes);
  }
}
