import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { ResourceService } from 'modules/resources/services/resource.service';
import { ResourceDetailsComponent } from './view/resource-details.component';
import { Transition } from '@uirouter/core';
import { lastValueFrom } from 'rxjs';


export const resourcesRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.resource',
    url: '^/resources/:id',
    params: {
      id: null
    },
    component: ResourceDetailsComponent,
    data: {
      label: 'Resource',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'resource',
        deps: [Transition, ResourceService],
        useFactory: (transition: Transition, resourceService: ResourceService) => {
          return lastValueFrom(resourceService.get(Number(transition.params().id)));
        }
      }
    ]
  }
];
