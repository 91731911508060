import { Component, Input, OnInit } from '@angular/core';
import { IAnswerVariant, IQuestion, IQuizResult } from 'modules/quiz/models/quiz.model';
import { questionTypeExtension, QuizQuestionType } from 'modules/quiz';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';
import { QuizResultService } from 'modules/quiz/services/quiz-result.service';


@Component({
  selector: 'quiz-player-question',
  templateUrl: './quiz-player-question.component.html'
})
export class QuizPlayerQuestionComponent implements OnInit {
  static readonly selector = 'quizPlayerQuestion';
  @Input() question: IQuestion;
  @Input() quizResult: IQuizResult;
  @Input() scoreTypeId: number;
  tmpFiles = {};
  quizQuestionType = QuizQuestionType;
  questionTypeExtension = questionTypeExtension;

  constructor(
    private quizTemporaryFilesService: QuizTemporaryFilesService,
    public quizResultService: QuizResultService,
  ) { }

  ngOnInit() {
    this.tmpFiles = this.quizTemporaryFilesService.getFiles();
  }

  choiceChecked(choice: IAnswerVariant) {
    this.question.answerVariants.forEach((variant) => {
      variant.selected = false;
    });
    choice.selected = true;
  }

  showQuestionCorrectness(): boolean {
    return this.quizResult && this.quizResult.quiz && this.quizResultService.isCompleted(this.quizResult) &&
      this.question.typeId !== QuizQuestionType.shortAnswer &&
      (this.quizResult.quiz.showQuestionCorrectness === 1 ||
        this.quizResult.quiz.showQuestionCorrectness === 2 &&
        (this.quizResult.attempt === this.quizResult.quiz.attemptsAllowed ||
          [2, 3].includes(this.quizResult.statusId)));
  }


  isSuccessAnswer(variant: IAnswerVariant): boolean {
    return this.isScorable() &&
      (!this.quizResult || this.showQuestionCorrectness()) && variant.correct &&
      this.question && this.question.typeId !== QuizQuestionType.fillin &&
      this.question.typeExtensionId !== questionTypeExtension.likertScale.id;
  }

  isFailedAnswer(variant: IAnswerVariant): boolean {
    return this.isScorable() &&
      (!this.quizResult || this.showQuestionCorrectness()) && variant.correct === false &&
      variant.selected && this.question.typeExtensionId !== questionTypeExtension.likertScale.id;
  }

  matchingChose(matching: IAnswerVariant) {
    this.question.answerVariants.forEach((variant) => {
      if (variant !== matching && matching.answerText && matching.answerText === variant.answerText) {
        variant.answerText = null;
      }
    });
  }

  private isScorable(): boolean {
    return !!((this.quizResult?.quiz?.scoreTypeId || this.scoreTypeId) ||
      (!this.quizResult && this.scoreTypeId));
  }
}
