<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="cancelModal()"><span
          aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <div *ngFor="let item of items" class="checkbox">
    <label><input type="checkbox" [(ngModel)]="item.selected" (change)="onCheckedChanged(item)" /> {{item.text}}</label>
  </div>
</div>
<div class="modal-footer" *ngIf="isModal">
  <button type="button" class="btn btn-warning" (click)="cancelModal()">Cancel</button>
  <button type="button" class="btn btn-success" (click)="applyFilter()">Apply</button>
</div>
