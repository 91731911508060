import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRole } from 'modules/admin/roles/models/role.model';


enum UserRolesServiceEndpoints {
  GET = '/a/user/roles/'
}


@Injectable()
export class UserRolesService {
  constructor(private http: HttpClient) {}

  getRoles(includeInvisible?: boolean): Observable<IRole[]> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('include_invisible', includeInvisible);

    return this.http.get<any>(UserRolesServiceEndpoints.GET, { params: httpParams });
  }
}
