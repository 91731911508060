import { Component, Input, OnInit } from '@angular/core';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';


@Component({
  selector: 'number-choice-filter',
  templateUrl: './number-choice-filter.component.html'
})
export class NumberChoiceFilterComponent implements OnInit {
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Input() filterService: ISearchFilterService;

  filter: ISearchFilter;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
  }

  blur($event: Event) {
    ($event?.target as HTMLInputElement)?.blur();
  }

  applyFilter(value: string, term?: string) {
    this.filterService.applyFilter(value, term);
  }
}
