import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { IResourceCategory, IResourceCategoryPath, IResourceCategoryPayload } from '../models/resource-category.model';
import { ElmsUtils } from 'core/utils';
import { HttpRequestParams } from 'core/services';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export interface IQueueResponse<T> {
  count: number;
  items: T[];
}

export enum ResourceCategoryEndpoints {
  QUEUE = '/a/resources/categories/',
  PATH = '/a/resources/categories/:categoryId/path/',
  GET = '/a/resources/categories/:categoryId/',
  PUT = '/a/resources/categories/:categoryId/',
  POST = '/a/resources/categories/'
}

@Injectable()
export class ResourceCategoryService {
  notifyCategoryQueueReload = new EventEmitter<void>();

  constructor(private http: HttpClient) {}

  public queue(params: HttpParams | HttpRequestParams): Observable<IQueueResponse<IResourceCategory>> {
    return this.http.get<IQueueResponse<IResourceCategory>>(
      ResourceCategoryEndpoints.QUEUE,
      { params }
    );
  }

  public getPath(categoryId: number): Observable<IResourceCategoryPath[]> {
    return this.http.get<IResourceCategoryPath[]>(
      ResourceCategoryEndpoints.PATH.replace(
        ':categoryId',
        categoryId.toString()
      )
    );
  }

  public get(categoryId: number): Observable<IResourceCategory> {
    return this.http.get<IResourceCategory>(
      ResourceCategoryEndpoints.GET.replace(
        ':categoryId',
        categoryId.toString()
      )
    );
  }

  public delete(categoryId: number, throwHttpErrors = false): Observable<IResourceCategory> {
    return this.http.delete<IResourceCategory>(
      ElmsUtils.formatUrl(ResourceCategoryEndpoints.GET, { categoryId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public update(categoryId: number, payload: Partial<IResourceCategoryPayload>): Observable<IResourceCategory> {
    return this.http.put<IResourceCategory>(
      ResourceCategoryEndpoints.PUT.replace(
        ':categoryId',
        categoryId.toString()
      ),
      payload
    );
  }

  public post(payload: Partial<IResourceCategory>): Observable<IResourceCategory> {
    return this.http.post<IResourceCategory>( ResourceCategoryEndpoints.POST, payload);
  }
}
