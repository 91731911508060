import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filterMapping, IFilterComponentsMap } from './index';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';


@Component({
  selector: 'popup-multi-choice-filter',
  templateUrl: './popup-multi-choice.component.html'
})
export class PopupMultiChoiceComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  filter: ISearchFilter;

  private modalInstance?: NgbModalRef;
  private subscriber: Unsubscribable;

  constructor(private ngModal: NgbModal, private injector: Injector) { }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.subscriber = this.filterService.conditionsChanged().subscribe(() => this.ngOnConditionsChanged());
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnConditionsChanged() {
    this.modalInstance?.dismiss();
  }

  openFilter() {
    this.modalInstance = this.ngModal.open(filterMapping[this.filter.popupOptions.component], {
      injector: this.injector
    });

    const component = this.modalInstance.componentInstance as IFilterComponentsMap;

    component.filterService = this.filterService;
    component.modalInstance = this.modalInstance;
  }

  clearSelection() {
    this.filterService.clear();
  }
}
