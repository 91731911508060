import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { lastValueFrom } from 'rxjs';
import { UserAchievementsComponent } from './components/user-achievements.component';
import { UserAchievementsService } from './services/user-achievements.service';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { AchievementsLeaderboardComponent } from './components/achievements-leaderboard.component';


export const userAchievementsRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.achievements',
    url: '/achievements',
    data: {
      label: 'Achievements',
      availableFor: ['regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'user',
        deps: [ CurrentUserService, UserAchievementsService ],
        useFactory: (
          currentUserService: CurrentUserService, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getUser(currentUserService.get().id));
        }
      },
      {
        provide: 'userAchievements',
        deps: [ CurrentUserService, UserAchievementsService ],
        useFactory: (
          currentUserService: CurrentUserService, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getAchievements(currentUserService.get().id));
        }
      },
      {
        provide: 'availableAchievements',
        deps: [ UserAchievementsService, CurrentUserService ],
        useFactory: async (
          userAchievementsService: UserAchievementsService, currentUser: CurrentUserService
        ) => {
          const id = currentUser.get().id;

          return userAchievementsService.updateAchievementsForUser(
            await lastValueFrom(userAchievementsService.getAchievements(id)),
            await lastValueFrom(userAchievementsService.getAvailable(id))
          );
        }
      },
      {
        provide: 'stats',
        deps: [ CurrentUserService, UserAchievementsService ],
        useFactory: (
          currentUserService: CurrentUserService, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getStats(currentUserService.get().id));
        }
      }
    ],
    component: UserAchievementsComponent
  },
  {
    name: 'main.user_achievements',
    url: '/user/:userId/achievements',
    data: {
      label: 'Achievements',
      availableFor: ['regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'user',
        deps: [ Transition, UserAchievementsService ],
        useFactory: (
          transition: Transition, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getUser(transition.params().userId));
        }
      },
      {
        provide: 'userAchievements',
        deps: [ Transition, UserAchievementsService ],
        useFactory: (
          transition: Transition, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getAchievements(transition.params().userId));
        }
      },
      {
        provide: 'availableAchievements',
        deps: [ Transition, UserAchievementsService, CurrentUserService ],
        useFactory: async (
          transition: Transition, userAchievementsService: UserAchievementsService, currentUser: CurrentUserService
        ) => {
          const id = transition.params().userId;
          const availableAchievements = userAchievementsService.updateAchievementsForUser(
            await lastValueFrom(userAchievementsService.getAchievements(id)),
            await lastValueFrom(userAchievementsService.getAvailable(id))
          );

          if (+id !== +currentUser.get().id) {
            return availableAchievements.filter(el => el.achieved);
          } else {
            return availableAchievements;
          }
        }
      },
      {
        provide: 'stats',
        deps: [ Transition, UserAchievementsService ],
        useFactory: (
          transition: Transition, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getStats(transition.params().userId));
        }
      }
    ],
    component: UserAchievementsComponent
  },
  {
    name: 'main.leaderboard',
    url: '/user/:id/leaderboard',
    data: {
      label: 'Leaderboard',
      availableFor: ['regularUser', 'admin']
    },
    resolve: [
      {
        provide: 'user',
        deps: [ Transition, UserAchievementsService ],
        useFactory: (
          transition: Transition, userAchievementsService: UserAchievementsService
        ) => {
          return lastValueFrom(userAchievementsService.getUser(transition.params().id));
        }
      },
      {
        provide: 'users',
        deps: [ Transition, UserAchievementsService ],
        useFactory: async (
          transition: Transition, userAchievementsService: UserAchievementsService
        ) => {
          const stats = await lastValueFrom(userAchievementsService.getStats(transition.params().id));

          return userAchievementsService.getLeaderboardUsers(stats);
        }
      }
    ],
    component: AchievementsLeaderboardComponent
  }
];
