import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { CourseService } from 'modules/course/common/services/course.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';


@Injectable()
export class CourseFormatFilterService extends BaseSearchFilterService {
  public filter: ISearchFilter = {
    name: 'courseFormatFilter',
    label: 'Format',
    term: 'format_id',
    mode: 'multiChoice',
    multiChoice: true,
    open: true,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  constructor(urlService: UrlParamService, protected courseService: CourseService) {
    super(urlService);
  }

  public load(): void {
    this.courseService.getCourseOptions().subscribe(options => {
      this.filter.items = options.formats.map(i => ({ id: i.id, text: i.name, value: i.id.toString() }));

      this.initSelectedItems();
    });
  }
}
