import { Injectable, Injector, Type } from '@angular/core';
import { ObjectFilterService } from 'modules/search/common/services/filters/object-filter.service';
import { ISearchFilterService } from 'modules/search/models/search-filters.models';
import { BaseSearchFilterService } from './filters/base-search.service';
import { CourseFormatFilterService } from './filters/course-format-filter.service';
import { CourseLabelFilterService } from './filters/course-label-filter.service';
import { OrganizationFilterService } from './filters/organization-filter.service';
import { TagSearchFilterService } from './filters/tag-search-filter.service';
import { CreditTypeFilterService } from './filters/credit-type-filter.service';
import { SubjectFilterService } from './filters/subject-filter.service';
import { TargetAudienceFilterService } from './filters/target-audience-filter.service';
import { LsSearchFilterService } from './filters/ls-search-filter.service';
import { CourseFormatTypeFilterService } from './filters/course-format-type-filter.service';


@Injectable()
export class SearchFiltersMappingService {
  protected readonly mapping: Record<string, Type<BaseSearchFilterService>> = {
    'objectFilter': ObjectFilterService,
    'tagFilter': TagSearchFilterService,
    'courseFormatFilter': CourseFormatFilterService,
    'courseLabelFilter': CourseLabelFilterService,
    'creditTypeFilter': CreditTypeFilterService,
    'subjectFilter': SubjectFilterService,
    'targetAudiencesFilter': TargetAudienceFilterService,
    'lsFilter': LsSearchFilterService,
    'courseFormatTypeFilter': CourseFormatTypeFilterService,
    'organizationFilter': OrganizationFilterService
  };

  constructor(private injector: Injector) {}

  get(name: string): ISearchFilterService | null {
    if (name in this.mapping) {
      return this.injector.get<ISearchFilterService>(this.mapping[name]);
    }

    return null;
  }
}
