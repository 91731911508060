<div class="modal-body achievement-modal">

  <div class="achievement-modal-image-overlay">
    <img *ngIf="achievement.achieved" alt="Achievement Badge"
         [src]="'/a/media/file/'+ achievement.achieved.badgeFile.id + '/?rnd=' + achievement.achieved.badgeFile.rnd"
         class="image-achievement sm-margin-bottom user-achievement-image">

    <img *ngIf="!achievement.achieved && achievement.badgeFile.rnd" alt="Achievement image"
         [src]="'/a/media/file/'+ achievement.badgeFile.id + '/?rnd=' + achievement.badgeFile.rnd"
         class="image-achievement sm-margin-bottom user-achievement-image">

    <thumbnail *ngIf="badgeFile"
               [file]="badgeFile.file"
               [params]="{width: 64, height:64 }"
               class="image-achievement sm-margin-bottom user-achievement-image"></thumbnail>

    <div class="modal-achievement-badge-mark"
         [ngClass]="{'success-badge':achievement.achieved, 'timed-badge':achievement.endDate && !achievement.achieved}"
         *ngIf="(achievement.endDate && !achievement.achieved) || achievement.achieved">
    </div>

  </div>

  <div class="achievement-modal-points" *ngIf="leaderboardEnabled">
    <span class="user-achievement-points" *ngIf="!achievement.achieved" [ngPlural]="achievement.points">
       <ng-template ngPluralCase="one">1 point</ng-template>
       <ng-template ngPluralCase="other">{{achievement.points}} points</ng-template>
    </span>
    <span class="user-achievement-points" *ngIf="achievement.achieved && achievement.achieved.pointValue"
          [ngPlural]="achievement.achieved.pointValue">
      <ng-template ngPluralCase="one">1 point</ng-template>
      <ng-template ngPluralCase="other">{{achievement.points}} points</ng-template>
    </span>
  </div>


  <div class="text-right">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <div *ngIf="achievement.achieved">
    <h4 class="achievement-modal-title">{{achievement.achieved.message}}</h4>

    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
          <div *ngIf="achievement.achieved.achievementTypeId === AchievementType.HOURS_AMOUNT ">
            <div class="">
              <span>Complete  <strong>
                <span [ngPlural]="achievement.achieved.threshold">
                    <ng-template ngPluralCase="one">1 hour</ng-template>
                    <ng-template ngPluralCase="other">{{achievement.achieved.threshold}} hours</ng-template>
                </span>
              </strong>
                ({{progress.completedPoints}}/{{progress.totalPoints}})</span>
            </div>
          </div>

          <div *ngIf="achievement.achieved.achievementTypeId === AchievementType.COURSES_AMOUNT">
            <div class="">
              <span>Complete any <strong>
                <span [ngPlural]="achievement.achieved.threshold">
                    <ng-template ngPluralCase="one">1 course</ng-template>
                    <ng-template ngPluralCase="other">{{achievement.achieved.threshold}} courses</ng-template>
                </span>
              </strong>
                ({{progress.completedPoints}}/{{progress.totalPoints}})</span>
            </div>
          </div>

          <div *ngIf="achievement.achieved.objectData.courses && achievement.achieved.objectData.courses.length">
            <div *ngIf="achievement.achieved.objectData.courses.length===1" class="text-left">
              <span>Complete <a uiSref="main.course"
                                [uiParams]="{id:achievement.achieved.objectData.courses[0].courseId}" href="#"
                                title="Go to {{achievement.achieved.objectData.courses[0].courseName}} course">{{achievement.achieved.objectData.courses[0].courseName | htmlToPlainText}}</a></span>
            </div>
            <div *ngIf="achievement.achieved.objectData.courses.length>1" class="text-left">
              <span>Complete {{progress.totalPoints}} courses ({{progress.completedPoints}}/{{progress.totalPoints}}
                )</span>
              <div *ngFor="let ac of achievement.achieved.objectData.courses">
                <span><a uiSref="main.course" [uiParams]="{id:ac.courseId}"
                         title="Go to {{ac.courseName}} course">{{ac.courseName}}</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="secondary-panel mt-10" *ngIf="achievement.achieved.description">
      {{achievement.achieved.description}}
    </div>

    <!--progress bar-->
    <div *ngIf="progress && selfView" class="d-flex">
      <ngb-progressbar class="achievement-progress w-100" type="primary" [value]="progress.percentage" [max]="100"
                       [ngClass]="{'achievement-progress-completed':progress.percentage===100}"
                       title="progressLabel">
      </ngb-progressbar>
    </div>

  </div><!--endif achieved-->


  <div *ngIf="!achievement.achieved">
    <h4 class="achievement-modal-title">{{achievement.message}}</h4>

    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
          <div *ngIf="achievement.achievementTypeId === AchievementType.HOURS_AMOUNT">
            <div>
                <span>Complete
                  <strong>
                     <span [ngPlural]="achievement.threshold">
                        <ng-template ngPluralCase="one">1 hour</ng-template>
                        <ng-template ngPluralCase="other">{{achievement.threshold}} hours</ng-template>
                     </span>
                  </strong>
                  ({{progress.completedPoints}}/{{progress.totalPoints}})</span>
            </div>
          </div>
          <div *ngIf="achievement.achievementTypeId === AchievementType.COURSES_AMOUNT">
            <div>
              <span>Complete any
                <strong>
                  <span [ngPlural]="achievement.threshold">
                    <ng-template ngPluralCase="one">1 course</ng-template>
                    <ng-template ngPluralCase="other">{{achievement.threshold}} courses</ng-template>
                  </span>
                </strong>
                ({{progress.completedPoints}}/{{progress.totalPoints}})</span>
            </div>
          </div>
          <div *ngIf="achievement.groupAchievementCourses && achievement.groupAchievementCourses.length">
            <div *ngIf="achievement.groupAchievementCourses.length===1">
              <span>Complete <a uiSref="main.course" [uiParams]="{id:achievement.groupAchievementCourses[0].courseId}"
                                href="#"
                                title="Go to {{achievement.groupAchievementCourses[0].name}} course">{{achievement.groupAchievementCourses[0].name | htmlToPlainText}}</a></span>
            </div>
            <div *ngIf="achievement.groupAchievementCourses.length>1">
              <span>Complete {{progress.totalPoints}} courses ({{progress.completedPoints}}/{{progress.totalPoints}}
                )</span>
            </div>
            <div>
              <!-- start-end dates-->
              <div class="mt-5 mb-5" *ngIf="achievement.startDate && achievement.endDate">
                between <span>{{achievement.startDate| date: 'MMM d, y'}}</span> and
                <span>{{achievement.endDate| date: 'MMM d, y'}} </span>
              </div>
              <!--end date only-->
              <div class="mt-5 mb-5" *ngIf="achievement.endDate && !achievement.startDate">
                by <span>{{achievement.endDate| date: 'MMM d, y'}}</span>
              </div>

              <div class="mt-5 mb-5" *ngIf="achievement.startDate && !achievement.endDate">
                after <span>{{achievement.startDate| date: 'MMM d, y'}}</span>
              </div>
            </div>
            <div *ngIf="achievement.groupAchievementCourses.length>1">
              <div *ngFor="let achievementCourse of achievement.groupAchievementCourses">
                <span><a uiSref="main.course" [uiParams]="({id:achievementCourse.courseId})" href="#"
                         title="Go to {{achievementCourse.name}} course">{{achievementCourse.name}}</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="secondary-panel mt-10" *ngIf="achievement.description">
      {{achievement.description}}
    </div>

    <div *ngIf="progress" class="d-flex">
      <ngb-progressbar class="achievement-progress w-100" type="primary" [value]="progress.percentage" [max]="100"
                       title="progressLabel">
        <!-- <span id="progressLabel" class="progress-label">{{progress.percentage}}%</span> -->
      </ngb-progressbar>
    </div>

  </div><!--endif !achieved-->

  <hr>

  <div class="text-center font-semi-bold text-capitalize">
    <div *ngIf="!achievement.achieved">
      NOT YET ACHIEVED
    </div>

    <div *ngIf="achievement.achieved" class="text-success">
      ACHIEVED ON {{achievement.achieved.createdDate| date: 'MMM d, y' }}
    </div>
  </div>

</div>
