import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'keyword-search-form',
  templateUrl: './keyword-search-form.component.html'
})
export class KeywordSearchFormComponent {
  @Input() value: string;
  @Input() placeholder = 'Search keyword';
  @Input() disabled?: boolean;
  @Input() pending?: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Output() formSubmit = new EventEmitter<void>();

  onSubmit() {
    const value = this.value?.trim();

    this.valueChange.emit(value);
    this.formSubmit.emit();
  }
}
