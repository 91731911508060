<div class="row no-margin-left no-margin-right">
  <div class="col-sm-12 col-xs-12 no-padding-left no-padding-right">
    <form class="no-margin-bottom" ngForm action (ngSubmit)="onSubmit()">
      <label for="query" class="sr-only">Search</label>
      <div class="input-group">
        <ng-content select=".search-controls"></ng-content>
        <input id="query" type="search" name="query" class="form-control" [title]="placeholder"
               [disabled]="disabled" [maxlength]="250"
               [placeholder]="placeholder" [(ngModel)]="value" autoFocus>

        <span class="input-group-btn">
          <button type="submit" class="btn btn-default" aria-label="Search" title="Search"
                  data-spinner-color="#000" [ladda]="disabled || pending">
            <span class="fa fa-search" aria-hidden="true"></span>
          </button>
        </span>
      </div>
    </form>
  </div>
</div>
