import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SavedSearchesServive } from 'modules/search/services/saved-searches.service';
import { ISavedSearchItem } from 'modules/search/models/saved-searches.models';


@Component({
  selector: 'manage-saved-searches-modal',
  templateUrl: './manage-saved-searches.component.html',
})
export class ManageSavedSearchesModalComponent {
  savedSearches: ISavedSearchItem[];
  draft: ISavedSearchItem;
  processing: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private savedSearchesServive: SavedSearchesServive
  ) { }

  editItem(item: ISavedSearchItem) {
    item.editing = true;
    this.draft = { ...item };
  }

  submit(item: ISavedSearchItem) {
    const { name, notify } = this.draft;

    this.processing = true;
    this.savedSearchesServive.update(this.draft.id, { name, notify }).subscribe(saved => {
      item.name = saved.name;
      item.notify = saved.notify;
      this.processing = false;
      this.cancelEdit(item);
    });
  }

  cancelEdit(item: ISavedSearchItem) {
    item.editing = false;
    this.draft = null;
  }

  removeItem(item: ISavedSearchItem) {
    item.pending = true;
    this.savedSearchesServive.delete(item.id).subscribe(() => {
      item.pending = false;
      this.savedSearches = this.savedSearches.filter(i => i.id !== item.id);

      if (!this.savedSearches.length) {
        this.activeModal.close(this.savedSearches);
      }
    });
  }

  close() {
    this.activeModal.close(this.savedSearches);
  }
}
