<back-button></back-button>

<div class="mb-15 d-flex align-items-center-sm flex-direction-column-xs space-between">
  <h2 class="no-margin-top no-margin-bottom hyphens-auto xs-margin-bottom-phone" style="font-size: 30px;">
    {{userApplication.application.name}}
  </h2>
</div>

<div class="course-wizard-panel">
  <external-application-keys [application]="userApplication.application"></external-application-keys>
</div>
