import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import {
  ISearchFilter, ISearchFilterItem, ISearchFilterService
} from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';


@Component({
  selector: 'multi-choice-filter',
  templateUrl: './multi-choice-filter.component.html'
})
export class MultiChoiceFilterComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  filter: ISearchFilter;
  searchQuery:string|null = null;
  status = {
    changed: false
  };

  displayLimit: number;

  private subscriber: Unsubscribable;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.displayLimit = this.filter.displayLimit;
    this.subscriber = this.filterService.conditionsChanged().subscribe(() => this.ngOnConditionsChanged());
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnConditionsChanged() {
    if (!this.filter.visible) {
      this.searchQuery = null;
    }
  }

  get filteredFilterItems(): ISearchFilterItem[] {
    return _.filter(this.filter.items, (item) => {
      return this.searchQuery ? item.text.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
    }).slice(0, this.fullScreen ? undefined : this.displayLimit);
  }

  applyFilter(value: string, term?: string) {
    this.filterService.applyFilter(value, term);
  }

  applyFilterItems() {
    const items = this.filter.items.filter(i => i.selected === true);

    if (!items.length) {
      this.filterService.clear();
    } else {
      this.filterService.applyItems(items);
    }

    this.isOpen = false;
    this.status.changed = false;
  }

  setStatus() {
    const selectedItems = _.filter(this.filter.items, { selected: true });

    this.status.changed = !_.isEqual(selectedItems, this.filter.selectedItems);
  }

  moreItems() {
    this.displayLimit = this.filter.items.length;

    return false;
  }

  lessItems() {
    this.displayLimit = this.filter.displayLimit;

    return false;
  }

  isAllSelected() {
    return this.filter.items.every(i => i.selected === true);
  }

  selectAll(items: ISearchFilterItem[]) {
    const value = !this.isAllSelected();
    const changed = items.filter(i => i.selected !== value);

    this.filterService.applyItems(changed);
    items.forEach(i => i.selected = value);
  }

  searchQueryChanged() {
    if (this.searchQuery?.length) {
      this.moreItems();
    } else {
      this.lessItems();
    }
  }
}
