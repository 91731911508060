import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { ISearchFilter } from 'modules/search/models/search-filters.models';
import { SearchService } from 'modules/search/services';


@Injectable()
export class ObjectFilterService extends BaseSearchFilterService {
  public filter: ISearchFilter = {
    name: 'objectFilter',
    label: 'Object Type',
    term: 'type',
    mode: 'singleChoice',
    multiChoice: false,
    open: true,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  constructor(urlService: UrlParamService, private searchService: SearchService) {
    super(urlService);
  }

  public load(): void {
    if (!this.filter.items.length) {
      this.searchService.getContentTypes().subscribe(types => {
        this.filter.items = types.map(i => ({ text: i.name, value: i.value }));

        this.initSelectedItems();
      });
    } else {
      this.initSelectedItems();
    }
  }
}
