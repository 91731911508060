<ul class="list-group">
  <li class="list-group-header">
    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
          <strong>Course name</strong>
        </div>
        <div class="flex-col flex-col-10x">
          <strong>My target date</strong>
        </div>
        <div class="flex-col flex-col-10x">
          <strong>Due date</strong>
        </div>
        <div class="flex-col flex-col-15x">
          <strong>Status</strong>
        </div>
        <div class="flex-col flex-col-2x"></div>
      </div>
    </div>
  </li>
  <li class="list-group-item" *ngFor="let userCourse of userCourses">
    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">{{userCourse.course.name}}</div>
        <div class="flex-col flex-col-10x"></div>
        <div class="flex-col flex-col-10x"></div>
        <div class="flex-col flex-col-15x">
          <course-registration-status [regData]="userCourse.lastRegistration"></course-registration-status>
        </div>
        <div class="flex-col flex-col-2x">
          <registration-menu [course]="userCourse.course" [registration]="userCourse.lastRegistration"
                             [showDetails]="true" [showHistory]="true"
                             [showRemoveAction]="true"></registration-menu>
        </div>
      </div>
    </div>
  </li>
</ul>

<div *ngIf="false">
  <div class="load-spinner"></div>
</div>
