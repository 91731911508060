import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { userNetworkRoutingModule } from './user-network-routing.module';
import { UserNetworkComponent } from './components/user-network.component';
import { FormsModule } from '@angular/forms';
import { NetworkModule } from '../../network/network.module';
import { UserProfileShortInfoComponent } from './components/user-profile-short-info-component';
import { UserModule } from '../user.module';
import { UserAchievementsModule } from '../../user-achievements/user-achievements.module';


@NgModule({
  imports: [
    LmsComponentsModule,
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: userNetworkRoutingModule }),
    UserAchievementsModule,
    FormsModule,
    NetworkModule,
    UserModule,
  ],
  declarations: [
    UserNetworkComponent,
    UserProfileShortInfoComponent
  ],
  providers: []
})
export class UserNetworkModule { }
