<div *ngIf="filter.visible">
  <div *ngIf="!fullScreen">
    <div ngbAccordion *ngIf="!filter.appearance || filter.appearance === 'panel'" class="truncate-labels">
      <div ngbAccordionItem [collapsed]="!isOpen">
        <div ngbAccordionHeader>
          <button ngbAccordionButton>
            <span class="pull-right fa fa-fw fa-caret-down"></span>
            <span>{{filter.label}}</span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="filter.loading">
                <div class="load-spinner"></div>
              </div>
              <ng-select *ngIf="!filter.loading"
                         [multiple]="filter.multiple"
                         [placeholder]="filter.placeholder || 'Start typing a name...'"
                         bindLabel="text"
                         [clearable]="false"
                         [minTermLength]="3"
                         [autoFocus]="true"
                         [(ngModel)]="filter.selectedItems"
                         [addTag]="false"
                         [trackByFn]="trackByFn"
                         (add)="applyFilter($event)"
                         (remove)="applyFilter($event)"
                         [compareWith]="compareFn"
                         [loading]="choicesLoading"
                         [items]="choicesFilter | async"
                         [typeahead]="choicesInput">
              </ng-select>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="filter.appearance === 'button'" class="btn-group btn-block"
         ngbDropdown [autoClose]="'outside'">
      <button id="SearchFilters" type="button"
              class="btn btn-default btn-block" ngbDropdownToggle>
        {{filter.label}} <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu class="dropdown-menu-right filter-dropdown">
        <div>
          <div *ngIf="filter.loading">
            <div class="load-spinner"></div>
          </div>

          <ng-select *ngIf="!filter.loading"
                     [items]="choicesFilter | async"
                     [multiple]="filter.multiple"
                     [placeholder]="filter.placeholder || 'Start typing a name...'"
                     bindLabel="text"
                     [clearable]="false"
                     [minTermLength]="3"
                     [autoFocus]="true"
                     [(ngModel)]="filter.selectedItems"
                     [addTag]="false"
                     [trackByFn]="trackByFn"
                     (add)="applyFilter($event)"
                     (remove)="applyFilter($event)"
                     [loading]="choicesLoading"
                     [compareWith]="compareFn"
                     [typeahead]="choicesInput">
          </ng-select>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="fullScreen" class="">

    <ul class="content-list">
      <li class="content-list-item search-item">
        <div class="mobile-prompt-item">
          <ng-select *ngIf="!filter.loading"
                     [items]="choicesFilter | async"
                     [multiple]="filter.multiple"
                     [placeholder]="filter.placeholder || 'Start typing a name...'"
                     bindLabel="text"
                     [clearable]="false"
                     [minTermLength]="3"
                     [autoFocus]="true"
                     [(ngModel)]="filter.selectedItems"
                     [addTag]="false"
                     [trackByFn]="trackByFn"
                     (add)="applyFilter($event)"
                     (remove)="applyFilter($event)"
                     [loading]="choicesLoading"
                     [compareWith]="compareFn"
                     [typeahead]="choicesInput">
          </ng-select>
        </div>
      </li>
    </ul>
  </div>
</div>
