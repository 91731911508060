import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUserSubjectArea } from '../../user/models/user.model';
import { ICourseSubjectArea, ICourseTopic } from '../../course/common/models/play-course.model';
import { ControlContainer, NgForm } from '@angular/forms';


interface ISelectedTopic extends ICourseTopic{
  selected?: boolean;
}

interface ISelectedArea extends ICourseSubjectArea{
  selected?: boolean;
  expanded?: boolean;
  topics: ISelectedTopic[];
}

@Component({
  selector: 'course-subject-areas',
  templateUrl: './subject-areas.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SubjectAreasComponent implements OnInit {
  @Input() subjectAreas: ISelectedArea[] = [];
  @Input() selectedSubjectAreas: IUserSubjectArea[] = [];
  @Output() selectedSubjectAreasChange = new EventEmitter<IUserSubjectArea[]>();
  @Input() selectedCategories: IUserSubjectArea[] = [];
  @Output() selectedCategoriesChange = new EventEmitter<IUserSubjectArea[]>();
  @Input() formOptions: NgForm;
  @Input() consistentView: boolean;
  @Input() showTitle: boolean;
  @Input() maxCount: number;
  @Input() areasOptional: boolean;

  flattenTopics: ISelectedTopic[] = [];

  ngOnInit() {
    this.flattenTopics = this.subjectAreas
      .reduce((result, area) => {
        return [].concat(result, area.topics);
      }, []);

    const areaIds = this.selectedCategories?.map(area => area.id) || [];
    const topicIds = this.selectedSubjectAreas?.map(area => area.id) || [];

    this.subjectAreas.forEach(area => {
      if (areaIds.includes(area.id)) {
        area.selected = true;
      }
    });

    this.flattenTopics.forEach(topic => {
      topic.selected = topicIds.includes(topic.id);
    });
  }

  selectArea(area: ISelectedArea) {
    area.topics.forEach(topic => topic.selected = area.selected);

    this.selectedCategories = this.subjectAreas.filter(area => area.selected);
    this.selectedSubjectAreasChange.emit(this.selectedSubjectAreas);
  }

  selectTopic(topic: ISelectedTopic, selected:boolean) {
    topic.selected = selected;
    const area = this.subjectAreas.find(area => area.topics.includes(topic));

    area.selected = area.topics.every(topic => topic.selected);

    this.selectedSubjectAreas = this.flattenTopics.filter(topic => topic.selected);
    this.selectedSubjectAreasChange.emit(this.selectedSubjectAreas);
  }

  isSubjectAreaDisabled(subjectArea: ISelectedArea) {
    if (subjectArea.selected || !this.maxCount) {
      return false;
    }

    const unselectedTopicsCount = subjectArea.topics.filter(topic => !this.selectedSubjectAreas.includes(topic)).length;

    return (this.maxCount - this.selectedSubjectAreas.length) < unselectedTopicsCount;
  }

  someSelected() {
    return this.selectedSubjectAreas && this.selectedSubjectAreas.length > 0;
  }

  expandSubjectArea(subjectArea: ISelectedArea) {
    subjectArea.expanded = !subjectArea.expanded;
  }
}
