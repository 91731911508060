import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import { IUrlParams, OnUrlParamsChange } from 'core/navigation/models/navigation-url.model';
import { SearchResultMode } from 'modules/search/models/search.model';
import { ISearchItem, SearchService } from 'modules/search/services';
import { Subject, Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html'
})
export class SearchResultComponent implements OnInit, OnDestroy, OnUrlParamsChange {
  static readonly selector = 'searchResult';

  @Input() mode: SearchResultMode;
  @Input() searchExportLink: string;
  @Input() itemsPerPage = 20;
  @Input() smallView: boolean;
  @Output() promise = new EventEmitter<boolean>();
  @Output() itemCountChanged = new EventEmitter<number>();
  @Input() submit?: Subject<void>;

  items: ISearchItem[] = [];
  currentPage = 1;
  processing = false;

  protected requestSubscriber?: Unsubscribable;

  private _itemCount: number;
  private submitSubscriber?: Unsubscribable;

  constructor(protected urlService: UrlParamService, protected searchService: SearchService) { }

  set itemCount(value: number) {
    this._itemCount = value;
    this.itemCountChanged.emit(value);
  }

  get itemCount() {
    return this._itemCount;
  }

  ngOnInit(): void {
    this.urlService.subscribe((p) => this.ngOnUrlParamsChange(p));
    this.submitSubscriber = this.submit?.subscribe(() => this.search(this.urlService.urlParams));
  }

  ngOnDestroy(): void {
    if (this.requestSubscriber) {
      this.requestSubscriber?.unsubscribe();
      delete this.requestSubscriber;
    }

    if (this.submitSubscriber) {
      this.submitSubscriber?.unsubscribe();
      delete this.submitSubscriber;
    }
  }

  ngOnUrlParamsChange(params: IUrlParams) {
    this.currentPage = Number(params.page) || 1;
    this.search(params);
  }

  pageChanged(page: number) {
    const filters = Object.assign({}, this.urlService.urlParams, { page: page > 1 && page || null });

    this.urlService.navigate(filters);
  }

  private search(searchQuery: IUrlParams) {
    const query = Object.assign({}, searchQuery, {
      count: this.itemsPerPage,
      startDoc: (this.currentPage - 1) * this.itemsPerPage || 0,
    },
    searchQuery.catalog_query ? { query: searchQuery.catalog_query as string, } : {});

    this.requestSubscriber?.unsubscribe();
    this.promise.emit(true);
    this.processing = true;
    this.requestSubscriber = this.searchService
      .search(query)
      .pipe(
        finalize(() => {
          this.processing = false;
          this.promise.emit(false);
          this.requestSubscriber?.unsubscribe();
          this.requestSubscriber = null;
        }))
      .subscribe((response) => {
        this.itemCount = response.count;
        this.items = response.items;
      });
  }
}
