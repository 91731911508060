import { Component, Input, OnInit } from '@angular/core';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';
import { finalize } from 'rxjs';


@Component({
  selector: 'user-courses',
  templateUrl: './user-courses.component.html',
})
export class UserCoursesComponent implements OnInit {
  @Input() userId: number;
  @Input() viewMode: string;

  loading = false;
  userCourses: IUserCourse[] = [];

  constructor(
    private userCourseService: UserCourseService,
  ) {}

  ngOnInit() {
    this.loadCourses();
  }

  loadCourses() {
    this.loading = true;
    this.userCourseService.query(this.userId)
      .pipe(
        finalize(() => this.loading = false),
      )
      .subscribe(response => {
        this.userCourses = response.courses;
      });
  }
}
