import { CourseFormatFilterService } from './filters/course-format-filter.service';
import { CourseFormatTypeFilterService } from './filters/course-format-type-filter.service';
import { CourseLabelFilterService } from './filters/course-label-filter.service';
import { CreditTypeFilterService } from './filters/credit-type-filter.service';
import { LsSearchFilterService } from './filters/ls-search-filter.service';
import { ObjectFilterService } from './filters/object-filter.service';
import { OrganizationFilterService } from './filters/organization-filter.service';
import { SubjectFilterService } from './filters/subject-filter.service';
import { TagSearchFilterService } from './filters/tag-search-filter.service';
import { TargetAudienceFilterService } from './filters/target-audience-filter.service';
import { SearchFiltersMappingService } from './search-filter-mapping.service';
import { SearchFiltersProviderService } from './search-filters-provider.service';


export const userSearchFilterProviders = [
  // providers
  { provide: SearchFiltersMappingService, useClass: SearchFiltersMappingService },
  SearchFiltersProviderService,

  // filters
  ObjectFilterService,
  TagSearchFilterService,
  CourseFormatFilterService,
  CourseLabelFilterService,
  CreditTypeFilterService,
  OrganizationFilterService,
  SubjectFilterService,
  TargetAudienceFilterService,
  LsSearchFilterService,
  CourseFormatTypeFilterService
];
