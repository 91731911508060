import { Injectable, OnDestroy } from '@angular/core';
import { ISearchFilter, ISearchFilterItem } from 'modules/search/models/search-filters.models';
import { BaseSearchFilterService } from './base-search.service';
import { UrlParamService } from 'core/navigation';
import {
  CourseProviderOrganizationService
} from 'modules/course/providers/organizations/services/course-provider-organization.service';
import { xor } from 'lodash';
import { finalize, Unsubscribable } from 'rxjs';


@Injectable()
export class OrganizationFilterService extends BaseSearchFilterService implements OnDestroy {
  public filter: ISearchFilter = {
    name: 'organizationFilter',
    label: 'Organization',
    placeholder: 'Type to refine (limit 50)',
    term: 'cp_organization_id',
    mode: 'promptingFilter',
    promptType: 'provider_organization',
    multiChoice: true,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  private subscriber: Unsubscribable;

  constructor(
    urlService: UrlParamService,
    protected organizationService: CourseProviderOrganizationService
  ) {
    super(urlService);
  }

  ngOnDestroy(): void {
    this.finalize();
  }

  protected override initSelectedItems(): void {
    const changed = this.filter.selectedOptions?.size > 0 && (
      !this.filter.selectedItems?.length
      || this.filter.selectedOptions.size > this.filter.selectedItems.length
      || ![...this.filter.selectedOptions].every(i => this.filter.selectedItems.some(j => j.value === i))
    );

    this.subscriber?.unsubscribe();

    if (changed) {
      const selectedOptions = Array.from(this.filter.selectedOptions.values());

      this.filter.loading = true;
      this.filter.selectedItems = this.filter.items.filter(i => this.filter.selectedOptions.has(i.value.toString()));
      this.subscriber = this.organizationService.query({ id: selectedOptions })
        .pipe(finalize(() => this.finalize()))
        .subscribe(orgs => {
          this.filter.items = orgs.map<ISearchFilterItem>(i => ({
            id: i.id,
            text: i.shortName || i.name,
            value: i.id.toString()
          }));

          xor<string>(selectedOptions, this.filter.items.map(i => i.value.toString()))
            .forEach(i => {
              this.filter.items.push({
                id: Number(i),
                text: `Organization ${i}`,
                value: i
              });
            });

          this.filter.selectedItems = this.filter.items;
          this.filter.visible = this.setVisibility();
        });
    } else {
      this.filter.items = this.filter.items.filter(i => this.filter.selectedOptions.has(i.value.toString()));
      this.filter.selectedItems = this.filter.items;
      this.filter.visible = this.setVisibility();
    }
  }

  private finalize(): void {
    this.filter.loading = false;
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }
}
