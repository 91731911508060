import { NgModule } from '@angular/core';
import { MandatoryLabelComponent } from './components/mandatory-label.component';


@NgModule({
  declarations: [
    MandatoryLabelComponent
  ],
  exports: [
    MandatoryLabelComponent
  ]
})
export class SettingsModule {}
