import { Injectable } from '@angular/core';
import { ISearchFilter } from 'modules/search/models/search-filters.models';
import { CourseFormatFilterService } from './course-format-filter.service';


@Injectable()
export class CreditTypeFilterService extends CourseFormatFilterService {
  public filter: ISearchFilter = {
    name: 'creditTypeFilter',
    label: 'Credit Type',
    term: 'credit_type_id',
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  public load(): void {
    this.courseService.getCourseOptions().subscribe(options => {
      this.filter.items = options.creditTypes.map(i => ({ id: i.id, text: i.name, value: i.id.toString() }));

      this.initSelectedItems();
    });
  }
}
