import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'user-learning-view-mode-switcher',
  templateUrl: './user-learning-view-mode-switcher.component.html',
})
export class UserLearningViewModeSwitcherComponent {
  @Output() viewModeChange = new EventEmitter<string>();
}
