import { Component, Input, OnInit } from '@angular/core';
import {
  ISearchFilter, ISearchFilterItem, ISearchFilterService
} from 'modules/search/models/search-filters.models';
import { SuggestionsService } from 'modules/search/services/suggestions.service';
import { concat, distinctUntilChanged, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';


@Component({
  selector: 'prompting-filter',
  templateUrl: './prompting-filter.component.html'
})
export class PromptingFilterComponent implements OnInit {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Input() isMultiple = false;

  filter: ISearchFilter;

  choicesFilter: Observable<ISearchFilterItem[]>;
  choicesLoading = false;
  choicesInput = new Subject<string>();
  choices: ISearchFilterItem[] = [];
  singeItem: ISearchFilterItem | null = null;
  status = {
    isOpen: this.isOpen,
    changed: false
  };

  constructor(private suggestionsService: SuggestionsService) { }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.filter.multiple = this.isMultiple ? true : this.filter.multiple;

    this.refreshItems();
  }

  trackByFn(item: ISearchFilterItem) {
    return item.id;
  }

  compareFn(item1: ISearchFilterItem, item2: ISearchFilterItem) {
    return item1.id === item2.id;
  }

  refreshItems() {
    this.choicesFilter = concat(
      of([]), // default items
      this.choicesInput.pipe(
        distinctUntilChanged(),
        switchMap(term => {
          if (term) {
            this.choicesLoading = true;

            return this.suggestionsService.getSuggestions(term, this.filter.promptType, true)
              .pipe(
                map(response => response.items
                  .filter(i => !this.filter.selectedItems.some(j => j.id === i.id))
                  .map<ISearchFilterItem>(i => ({
                    id: i.id,
                    text: i.name,
                    value: i.id.toString()
                  }))),
                catchError(() => of([])), // empty list on error
                finalize(() => this.choicesLoading = false)
              );
          }

          return of([]);
        })
      )
    );
  }

  applyFilter(item: ISearchFilterItem) {
    if (!item.value) {
      return;
    }

    // We should manually add/remove selected item to the filter's item collection,
    // because it consists only from selected items.
    if (!this.filter.selectedOptions?.has(item.value.toString())) {
      this.filter.items.push(item);
    } else {
      this.filter.items = this.filter.items.filter(i => i.value !== item.value);
    }

    // Apply selection to search filter
    this.filterService.applyFilter(item.value);
  }
}
