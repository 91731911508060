<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="cancelModal()"><span
          aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">Discipline</h4>
</div>

<div [ngClass]="{'modal-body':isModal, 'modal-body-overflow':isModal }">
  <cdk-tree [dataSource]="groupTags" [treeControl]="treeControl" class="tree-view search-filter-control">
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" cdkTreeNodePadding>
      <li>
        <div class="checkbox">
          <label>
            <input type="checkbox" name="" value="" [(ngModel)]="node.checked" (change)="checkAll(node)" />
            {{node.name}}
          </label>
        </div>
      </li>
    </cdk-nested-tree-node>

    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" cdkTreeNodePadding>
      <div class="tree-node">
        <button class="btn btn-default btn-xs tree-expand" cdkTreeNodeToggle *ngIf="node.items.length > 0"
                (click)="node.expanded=!node.expanded">
          <span class="fa " [ngClass]="{'fa-angle-down': !node.expanded, 'fa-angle-up': node.expanded}"></span>
        </button>
        <div class="checkbox">
          <label>
            <input type="checkbox" name="" value="" [(ngModel)]="node.checked" (change)="checkAll(node)" />
            {{node.name}}
          </label>
        </div>
      </div>
      <ul [hidden]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </ul>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>

<div class="modal-footer" *ngIf="isModal">
  <button type="button" class="btn btn-warning" (click)="cancelModal()">Cancel</button>
  <button type="button" class="btn btn-success" (click)="applyFilter()">Apply</button>
</div>
