import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { QuizQuestionType, QuizType } from 'modules/quiz';
import _ from 'lodash';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { IHybridResultType, IQuestionTypeExtension, IQuizOptions } from 'modules/admin/quiz/models/quiz.model';
import { GlobalConfig } from 'core/environment';
import { ICourseQuizTypes } from 'modules/course/quiz/models/course-quiz.models';


export enum QuizOptionsEndpoints {
  OPTIONS = '/a/quizzes/options/',
}


@Injectable()
export class QuizOptionsService {
  static readonly CACHE_KEY = 'quizOptions';

  constructor(
    private http: HttpClient,
    private globalConfig: GlobalConfig
  ) {  }

  getOptions():Observable<IQuizOptions> {
    return this.http.get<IQuizOptions>(QuizOptionsEndpoints.OPTIONS, {
      context: new HttpContext()
        .set(CACHE_NAME_TOKEN, QuizOptionsService.CACHE_KEY)
        .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
    });
  }

  getQuizQuestionTypes(scorable: boolean, quizTypeId: QuizType): Observable<IQuestionTypeExtension[]> {
    const settings = this.globalConfig.settings.quizEdit;

    return this.getOptions().pipe(
      map(options => {
        const allowedTypes = [];

        for (const type of options.questionTypes) {
          if (settings.availableQuestionTypes[quizTypeId].indexOf(type.id) !== -1) {
            for (const extension of type.extensions) {
              const typeExtensionTemplate = settings.typeExtensionTemplates[extension.typeExtensionId];

              if (typeExtensionTemplate) {
                extension.answerVariants = typeExtensionTemplate.answerVariants;
              }

              allowedTypes.push(extension);
            }

            allowedTypes.push(type);
          }
        }

        allowedTypes.sort((a, b) => a.order - b.order);

        return allowedTypes.filter(item => {
          if (scorable) {
            return item.scorable;
          }

          return true;
        });
      })
    );
  }

  isQuizTypeScorable(quizTypeId: QuizType):boolean {
    return ![
      QuizType.evaluation,
      QuizType.preEvaluation,
      QuizType.followupEvaluation,
      QuizType.survey
    ].includes(quizTypeId);
  }

  isQuizEvaluation(quizTypeId: QuizType):boolean {
    return [
      QuizType.evaluation,
      QuizType.preEvaluation,
      QuizType.followupEvaluation
    ].includes(quizTypeId);
  }

  isQuestionOptional(quizTypeId: QuizType, questionTypeId: QuizQuestionType):Observable<boolean>  {
    return this.getOptions().pipe(
      map(options => {
        return _.get(
          _.find(options.courseQuizTypes, { id: quizTypeId }),
          'allowOptionalQuestions') &&
          _.get(
            _.find(options.questionTypes, { id: questionTypeId }),
            'skipQuestionAvailable');
      })
    );
  }

  getHybridResultTypes(): Observable<IHybridResultType[]> {
    return this.getOptions().pipe(
      map(options => {
        return _.flatten(_.map(options.resultTypes, resultType => {  /* 1,Points based; 2,Pass/Fail*/
          if (resultType.id === 1) {
            return _.map(options.scoreTypes, scoreType => { /*1,Percentage; 2,Points*/
              return {
                id: (resultType.id + scoreType.id).toString(),
                name: scoreType.name,
                scoreTypeId: scoreType.id,
                resultTypeId: resultType.id
              };
            });
          } else {
            return {
              id: resultType.id.toString(),
              name: resultType.name,
              scoreTypeId: 2,
              resultTypeId: resultType.id
            };
          }
        }));
      })
    );
  }

  getCourseQuizTypes() : Observable<ICourseQuizTypes> {
    return this.getOptions()
      .pipe(map((options) => {
        const types: ICourseQuizTypes = {
          length: 3
        };

        const quizTypes = _.keyBy(_.filter(options.courseQuizTypes, { system: false }), 'id');

        _.map(quizTypes, function (type) {
          const category = _.find(options.courseQuizTypeCategories, { id: type.categoryId });

          type.categoryName = category.name;
        });

        types.preAssessment = quizTypes[QuizType.preAssessment];
        types.assessment = quizTypes[QuizType.assessment];
        types.evaluation = quizTypes[QuizType.evaluation];

        if (quizTypes[QuizType.preEvaluation]) {
          types.length++;
          types.preEvaluation = quizTypes[QuizType.preEvaluation];
        }

        if (quizTypes[QuizType.followupEvaluation]) {
          types.followupEvaluation = quizTypes[QuizType.followupEvaluation];
          types.length++;
        }

        return types;
      }));
  }
}
