import { Component, Input, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { ISearchRow, SearchResultMode } from 'modules/search/models/search.model';
import { ISearchItem } from 'modules/search/services';
import { ElmsUtils } from 'core/utils';
import { ICourseSearchItem } from 'modules/course/common/models/course-search.model';


@Component({
  selector: 'search-result-row',
  templateUrl: './search-result-row.component.html'
})
export class SearchResultRowComponent implements OnInit {
  static readonly selector = 'searchResultRow';
  @Input() mode: SearchResultMode = 'simpleList';
  @Input() disabled: boolean;
  @Input() smallView: boolean;
  rowItem: ISearchRow;

  constructor(private urlService: UrlService) { }

  @Input() set item(item: ISearchItem) {
    this.rowItem = item;
  }

  ngOnInit(): void {
    if (this.mode === 'tiles' && this.urlService.search().type !== 'course') {
      this.mode = 'simpleList';
    }
  }

  formatPrice(price: number): string {
    return ElmsUtils.formatPrice(price);
  }

  formatDigit(value: number, precision: number): string {
    return ElmsUtils.formatDigit(value, precision);
  }

  isCourseItem(value:ISearchRow): value is ICourseSearchItem  {
    return value.type === 'course';
  }

  isDocument(value:ISearchRow): boolean {
    return value.type === 'document';
  }

  isAuthor(value:ISearchRow): boolean {
    return value.type === 'author';
  }

  isUser(value:ISearchRow): boolean {
    return value.type === 'user';
  }

  isResource(value:ISearchRow): boolean {
    return value.type === 'resource';
  }

  isBlog(value:ISearchRow): boolean {
    return value.type === 'blog';
  }

  isUndefined(value:ISearchRow): boolean {
    return !this.isBlog(value) && !this.isResource(value) && !this.isUser(value) && !this.isAuthor(value) &&
      !this.isDocument(value) && !this.isCourseItem(value);
  }
}
