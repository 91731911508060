import { Injectable, OnDestroy } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import {
  ISearchFilter,
  ISearchFilterChanges,
  ISearchFilterItem,
  ISearchFilterService,
} from 'modules/search/models/search-filters.models';
import { Observable, Subject } from 'rxjs';


@Injectable()
export abstract class BaseSearchFilterService implements ISearchFilterService, OnDestroy {
  public filter: ISearchFilter;
  private changedSubject = new Subject<ISearchFilterChanges>();
  private extractSubject = new Subject<void>();

  constructor(private urlService: UrlParamService) {}

  ngOnDestroy() {
    this.changedSubject.complete();
    this.extractSubject.complete();
  }

  public filterChanged(): Observable<ISearchFilterChanges> {
    return this.changedSubject.asObservable();
  }

  public conditionsChanged(): Observable<void> {
    return this.extractSubject.asObservable();
  }

  public applyFilter(value: string | null, term: string = this.filter.term): boolean {
    const condition = value?.toString();
    const transaction = this.getTransaction([term]);
    const existingConditions = transaction.get(term) || new Set<string>();

    if (!condition) {
      existingConditions.clear();
    } else if (existingConditions.has(condition)) {
      existingConditions.delete(condition);

      for (const filter of this.clearDependents(this.filter.associateWith?.dependents, condition)) {
        transaction.set(filter, null);
      }
    } else {
      if (!this.filter.multiChoice) {
        existingConditions.clear();
      }

      existingConditions.add(condition);
    }

    return this.commit(transaction.set(term, existingConditions));
  }

  public applyItems(items: ISearchFilterItem[]): boolean {
    const terms = new Set<string>([this.filter.term, ...items.filter(i => i.term).map(i => i.term)]);
    const transaction = this.getTransaction([...terms]);

    items.forEach(i => {
      const term = i.term || this.filter.term;
      const condition = i.value?.toString();

      if (transaction.has(term)) {
        const existingConditions = transaction.get(term);

        if (!condition) {
          existingConditions.clear();
        } else if (existingConditions.has(condition)) {
          existingConditions.delete(condition);

          for (const filter of this.clearDependents(this.filter.associateWith?.dependents, condition)) {
            transaction.set(filter, null);
          }
        } else {
          if (!this.filter.multiChoice) {
            existingConditions.clear();
          }

          existingConditions.add(condition);
        }

        transaction.set(term, existingConditions);
      } else {
        transaction.set(term, new Set<string>([condition]));
      }
    });

    return this.commit(transaction);
  }

  public clear(): boolean {
    const transaction = new Map<string, Set<string>>(
      this.clearSubFilters([this.filter]).map<[string, null]>(filter => [filter, null])
    );

    return this.commit(transaction);
  }

  public restoreDefaults(): void {
    this.clear();
  }

  public extractConditions(): void {
    this.filter.selectedOptions = this.getConditions();

    this.initSelectedItems();
    this.extractSubject.next();
  }

  public representItem(item: ISearchFilterItem): string {
    return item.invertedText ? item.invertedText : item.text;
  }

  public represent(): string {
    const label = this.filter.label.at(-1) === 's' ? this.filter.label : `${this.filter.label}s`;

    return `${this.filter.selectedItems.length} ${label} Selected`;
  }

  /**
   * @description There is an option where the filter items could have `term` property,
   * this is a base method, and it doesn't support it yet. */
  protected initSelectedItems(): void {
    this.filter.items.forEach(item => {
      item.selected = this.filter.selectedOptions?.has(item.value.toString());
    });
    this.filter.selectedItems = this.filter.items.filter((item) => item.selected);

    if (this.filter.unassignedOption) {
      this.filter.unassignedOption.selected = this.filter.selectedOptions.has(
        this.filter.unassignedOption.value.toString()
      );

      if (this.filter.unassignedOption.selected) {
        this.filter.selectedItems.push(this.filter.unassignedOption);
      }
    }

    this.filter.visible = this.setVisibility();
  }

  protected addGenericItems(): void {
    if (this.filter.selectedOptions?.size) {
      const selectedOptions = Array.from(this.filter.selectedOptions.values());
      const selectedIds = new Map<string, ISearchFilterItem>(
        this.filter.selectedItems.map(i => [i.value.toString(), i])
      );

      for (let i = selectedOptions.length - 1; i >= 0; i--) {
        const item = selectedOptions[i];

        if (!selectedIds.has(item)) {
          this.filter.selectedItems.push({
            text: `${this.filter.label} ${item}`,
            value: item,
            id: Number(item)
          });
        }
      }
    }
  }

  protected setVisibility(): boolean {
    if (!this.filter.disabled && this.filter.associateWith) {
      const { filter, conditions } = this.filter.associateWith;
      let visible = this.filter.visible;

      if (filter) {
        if (conditions) {
          visible = conditions.filter(i => filter.selectedOptions.has(i)).length > 0;
        } else {
          visible = filter.selectedOptions.size > 0;
        }
      } else if (conditions) {
        visible = conditions.some(i => i in this.urlService.urlParams);
      }

      if (!visible && this.filter.visible && this.filter.selectedItems?.length) {
        this.clear();
      }

      return visible;
    }

    return !this.filter.disabled;
  }

  protected getConditions(term?: string): Set<string> {
    const params = this.urlService.urlParams;
    const key = term || this.filter.term;

    if (key in params) {
      const values = params[key];

      return new Set<string>(Array.isArray(values) ? values : [values]);
    }

    return new Set<string>();
  }

  private clearSubFilters(filters: ISearchFilter[]): string[] {
    return filters.flatMap<string>(filter => {
      if (filter.selectedItems.length) {
        const terms = filter.selectedItems.filter(i => !!i.term).map(i => i.term);

        if (!terms.includes(filter.term)) {
          terms.push(filter.term);
        }

        return filter.associateWith?.dependents
          ? [...terms, ...this.clearSubFilters(filter.associateWith.dependents)]
          : terms;
      }

      return [];
    });
  }

  private clearDependents(dependents: ISearchFilter[] | null, condition: string): string[] {
    return (dependents || []).flatMap<string>(filter => {
      if (filter.selectedItems.length) {
        const terms = filter.selectedItems.filter(i => i.parentValue === condition).map(i => i.term);

        if (filter.associateWith?.conditions?.includes(condition) && !terms.includes(filter.term)) {
          terms.push(filter.term);
        }

        return filter.associateWith?.dependents
          ? [...terms, ...this.clearDependents(filter.associateWith.dependents, condition)]
          : terms;
      }

      return [];
    });
  }

  private getTransaction(terms: string[]): Map<string, Set<string>> {
    const conditions = new Map<string, Set<string>>();
    const params = this.urlService.urlParams;

    for (const term of terms) {
      if (term && term in params) {
        const values = params[term];

        conditions.set(term, new Set<string>(Array.isArray(values) ? values : [values]));
      }
    }

    return conditions;
  }

  private commit(transaction: Map<string, Set<string>>): boolean {
    const filters: Record<string, string | string[]> = {};

    if (transaction?.size) {
      for (const [term, value] of transaction) {
        const values = Array.from(value?.values() || []);

        filters[term] = this.filter.multiChoice && values.length > 1 ? values : values[0] || null;
      }

      this.changedSubject.next(filters);

      return true;
    }

    return false;
  }
}
