
export const searchTerms = [
  'advanced', 'audience_id', 'author_id', 'competency_id', 'competency_node_id',
  'course_certificate_available', 'course_format_type_id', 'cp_organization_id', 'created_date', 'created_by_user',
  'credit_type_id', 'device_compatibility', 'distance', 'format_id', 'group_id', 'label_id', 'label2_id', 'language_id',
  'last_reviewed', 'ls_id', 'max_list_price', 'max_start_date', 'max_updated_date', 'min_start_date',
  'min_updated_date', 'perlc_course_attribute_id', 'provider_user_id', 'quality', 'query', 'rating',
  'filter', 'sort', 'subject', 'tag', 'type', 'national_cp_only',
  'approval_status_id', 'active', 'showInactive', 'expired', 'showExpired', 'showUnpublished', 'certificate',
  'required_courses_only', 'training_category', 'min_price', 'max_price', 'free', 'user_roles', 'include_subgroups',
  'page', 'my_organization'
];
