import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { StateService, Transition } from '@uirouter/core';
import {
  ExternalApplicationUserViewComponent
} from 'modules/user/external-applications/view/components/external-application-user-view.component';
import { lastValueFrom } from 'rxjs';
import {
  UserAccessKeysViewComponent
} from 'modules/user/external-applications/components/user-access-keys-view.component';
import {
  UserExternalApplicationService
} from 'modules/user/external-applications/services/user-external-application.service';


export const userExternalApplicationsRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.userAccessKeys',
    url: '^/user-access-keys/',
    data: {
      label: 'User access keys',
      availableFor: ['regularUser', 'admin'],
    },
    component: UserAccessKeysViewComponent,
    resolve: [
      {
        provide: 'user',
        deps: [CurrentUserService, Transition, StateService],
        useFactory: (currentUser: CurrentUserService, transition: Transition, stateService: StateService) => {
          const user = currentUser.get();

          if (!user.webServiceUser) {
            transition.abort();
            stateService.go('main.access-denied');
          }

          return user;
        }
      }
    ]
  },
  {
    name: 'main.userExternalApplication',
    url: '^/user-external-applications/{applicationId}',
    params: {
      applicationId: null
    },
    data: {
      label: 'Application details',
      availableFor: ['regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'userApplication',
        deps: [CurrentUserService, Transition, StateService, UserExternalApplicationService],
        useFactory: (
          currentUser: CurrentUserService, transition: Transition, stateService: StateService,
          applicationService: UserExternalApplicationService
        ) => {
          const user = currentUser.get();

          if (!user.webServiceUser) {
            transition.abort();
            stateService.go('main.access-denied');
          }

          return lastValueFrom(applicationService.get(Number(user.id), Number(transition.params().applicationId)));
        }
      }
    ],
    component: ExternalApplicationUserViewComponent
  }
];
